import React from 'react'
import parse from 'html-react-parser'
import * as S from './styles'

interface QuestionsProps {
  label: string
  valueInput: string
  href?: string | undefined
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined
}

const FaqQuestions: React.FC<QuestionsProps> = ({
  label,
  valueInput,
  href,
  onClick
}) => {
  const getLabel = (item: string) => {
    const indexStart = new RegExp(
      valueInput.replace(/ç/g, 'c').replace(/\?/g, '\\?'),
      'i'
    ).exec(item.replace(/ç/g, 'c'))?.index
    if (indexStart === undefined) {
      return item
    }
    const indexEnding = indexStart + valueInput.length

    const labelStart = item.substring(0, indexStart)
    const labelMiddle = item.substring(indexStart, indexEnding)
    const labelEnding = item.substring(indexEnding)

    const newLabel = `${labelStart}<p>${labelMiddle}</p>${labelEnding}`

    return newLabel
  }

  return (
    <S.QuestionsSearch href={href} onClick={onClick}>
      {parse(getLabel(label))}
    </S.QuestionsSearch>
  )
}

export default FaqQuestions
