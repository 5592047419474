import React, { useRef } from 'react'
import AffiliatesBanner from '../../../../assets/images/LandingPageV2/affiliatesBanners/affiliatesMainBanner.png'
import ResponsiveAffiliatesBanner from '../../../../assets/images/LandingPageV2/affiliatesBanners/responsiveAffiliatesMainBanner.png'
import FirstOverlap from '../../../../assets/images/LandingPageV2/affiliatesBanners/first-overlap.png'
import SecondOverlap from '../../../../assets/images/LandingPageV2/affiliatesBanners/second-overlap.png'
import ButtonOverlap from '../../../../assets/images/LandingPageV2/affiliatesBanners/button-overlap.png'
import * as S from './styles'
import { getElementVisibility } from '../..'

interface AffiliatesProps {
  scrollPosition: number
}

const Affiliates: React.FC<AffiliatesProps> = ({ scrollPosition }) => {
  const imagesRef: {
    mainBanner: React.MutableRefObject<HTMLDivElement | null>
    firstOverlap: React.MutableRefObject<HTMLDivElement | null>
    secondOverlap: React.MutableRefObject<HTMLDivElement | null>
    buttonOverlap: React.MutableRefObject<HTMLDivElement | null>
  } = {
    mainBanner: useRef(null),
    firstOverlap: useRef(null),
    secondOverlap: useRef(null),
    buttonOverlap: useRef(null)
  }

  return (
    <S.BannerContainerAffiliates
      ref={imagesRef.mainBanner}
      visible={getElementVisibility(
        imagesRef.secondOverlap.current,
        scrollPosition
      )}
    >
      <img src={AffiliatesBanner} />

      <img src={ResponsiveAffiliatesBanner} className="responsiveBanner" />

      <S.FirstOverlap
        visible={getElementVisibility(
          imagesRef.firstOverlap.current,
          scrollPosition
        )}
        ref={imagesRef.firstOverlap}
      >
        <img src={FirstOverlap} />
      </S.FirstOverlap>
      <S.SecondOverlap
        visible={getElementVisibility(
          imagesRef.secondOverlap.current,
          scrollPosition
        )}
        ref={imagesRef.secondOverlap}
      >
        <img src={SecondOverlap} />
      </S.SecondOverlap>
      <S.ButtonOverlap
        visible={getElementVisibility(
          imagesRef.buttonOverlap.current,
          scrollPosition
        )}
        ref={imagesRef.buttonOverlap}
      >
        <img src={ButtonOverlap} />
      </S.ButtonOverlap>
    </S.BannerContainerAffiliates>
  )
}

export default Affiliates
