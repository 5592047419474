import axios from 'axios'

export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3001/'

const api = axios.create({
  baseURL
})

export interface LoginUser {
  email?: string
  password?: string
}

export type LoginUserErrors = Partial<LoginUser>

export interface CreateUser {
  name?: string
  email?: string
  password?: string
  confPassword?: string
  phone?: string
  document_number?: string
}
export type CreateUserErrors = Partial<CreateUser>

const prefix = '/auth'

export const getUserApi = async (): Promise<any> => {
  const response = await api.get(`${prefix}/users`)
  return response
}

export const getAccessToken = async (token: string): Promise<any> => {
  const response = await axios.post(`${baseURL + prefix}/access-token`, {
    refreshToken: token
  })
  return response
}

export const signUpApi = async (data: LoginUser): Promise<any> => {
  const response = await api.post(`${prefix}/login`, data)
  return response
}

export const getUser = async (): Promise<any> => {
  const response = await api.get(`${prefix}/user`)
  return response
}

export const logoutUser = async (refreshToken: string): Promise<any> => {
  const response = await api.post(`${prefix}/logout`, {
    refreshToken
  })
  return response
}

export const registerUser = async (data: CreateUser): Promise<any> => {
  const response = await api.post(`${prefix}/register`, data)
  return response
}

export const newPasswordRecovery = async (data: any): Promise<any> => {
  // email
  const response = await api.post(`${prefix}/recoveryPassword`, data)
  return response
}

export const verifyCodePassword = async (data: any): Promise<any> => {
  // email + code 1
  const response = await api.post(`${prefix}/verifyCode`, data)
  return response
}

export const changeRecoveryPassword = async (data: any): Promise<any> => {
  // email + code 2
  const response = await api.post(`${prefix}/changePassword`, data)
  return response
}

export default api

export const apiInterceptor = (): number =>
  api.interceptors.response.use(
    response => response,
    async error => {
      const { response, config } = error

      if (response.status !== 401) {
        return Promise.reject(error)
      }

      try {
        const refToken =
          localStorage.getItem('refreshToken') ||
          sessionStorage.getItem('refreshToken')

        if (refToken) {
          const res = await getAccessToken(refToken)

          if (res.status === 200) {
            const result = res.data.body.accessToken
            api.defaults.headers.common.Authorization = `Bearer ${result}`

            if (localStorage.getItem('accessToken')) {
              localStorage.setItem('accessToken', result)
            } else {
              sessionStorage.setItem('accessToken', result)
            }

            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${result}`
            }
            return await axios(config)
          } else {
            localStorage.removeItem('refreshToken')
            sessionStorage.removeItem('refreshToken')

            throw Error('Could not obtain accessToken')
          }
        } else {
          throw Error('Could not found refreshToken')
        }
      } catch (error) {
        localStorage.removeItem('accessToken')
        sessionStorage.removeItem('accessToken')
        return Promise.reject(error)
      }
    }
  )
