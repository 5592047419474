import React from 'react'
// import { CheckoutBuilderProvider } from './contexts/CheckoutBuilder'
// import { Helmet } from 'react-helmet'
import { GeneralProvider } from './contexts/GeneralContext'
import ApplicationRoutes from './Routes'

import './styles/FontImport.css'
import GlobalStyles from './styles/GlobalStyles'

const App: React.FC = () => {
  return (
    <GeneralProvider>
      <GlobalStyles />
      <ApplicationRoutes />
    </GeneralProvider>
  )
}

export default App
