import styled from 'styled-components'

interface Props {
  dark?: boolean
}

export const Container = styled.div<Props>`
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 48px;
  background-color: ${props =>
    props.dark ? 'var(--white)' : 'rgba(26, 26, 26, 0.66)'};
  position: fixed;
  inset: 0;
  z-index: 99;
  backdrop-filter: blur(14px);

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1980px;
    height: 100%;

    > div > svg {
      > path:nth-child(5) {
        fill: ${props => (props.dark ? 'var(--primary)' : '')};
      }
    }
  }

  @media (max-width: 1270px) {
    gap: 24px;
    padding: 14px 24px;
  }
`

export const LogoContainer = styled.div`
  width: 100%;
  cursor: pointer;

  > svg {
    height: 28px;
  }
`

interface ResponsiveProps {
  opened?: boolean
  active?: boolean
}

export const Content = styled.div<ResponsiveProps>`
  display: flex;
  gap: 48px;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;

    > a {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--background);
      cursor: pointer;
      white-space: nowrap;
      height: 100%;
      text-decoration: none;
      display: flex;
      align-items: center;

      @media (max-width: 1024px) {
        text-align: start;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        padding: 16px 0;
        width: 100%;
      }
    }
  }

  > a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--background);
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    border-bottom: 1px solid white;

    @media (max-width: 1024px) {
      text-align: start;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      padding: 24px 0;
      width: 100%;
      border-bottom: 1px solid #ffffff4d;
    }
  }

  @media (max-width: 1270px) {
    gap: 32px;
  }

  @media (max-width: 1024px) {
    display: ${props => (props.opened ? 'flex' : 'none')};
    gap: 0;
    height: fit-content;
  }
`

export const Divisor = styled.div<ResponsiveProps>`
  height: 2px;
  width: 100%;
  border-radius: 100px;
  background-color: white;
  opacity: ${props => (props.active ? '1' : '0')};
  transition: 0.4s ease;
`

export const ButtonsContainer = styled.div<ResponsiveProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  > a {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 1270px) {
      font-size: 14px;
    }
  }

  > a:first-child {
    background: white;
    color: var(--primary);

    &:hover {
      background-color: var(--primary);
      transition: 0.3s ease;
      color: white;
    }
  }

  > a:last-child {
    background: var(--primary);
    color: white;

    &:hover {
      background-color: var(--seccondary);
      transition: 0.3s ease;
    }
  }

  @media (max-width: 1024px) {
    display: ${props => (props.opened ? 'flex' : 'none')};
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    > a {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      padding: 8px 10px;
      width: 100%;
      max-width: 100%;
    }
  }
`

export const Button = styled.a`
  display: flex;
  padding: 8px;
  width: 100%;
  max-width: 150px;
  cursor: pointer;
  border-radius: 5px;
  color: var(--white);
  justify-content: center;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const ToggleButton = styled.button`
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  color: white;

  @media (min-width: 1024px) {
    display: none;
  }
`

interface ContainerProps {
  opened?: boolean
}

export const ContainerResponsive = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background: rgba(26, 26, 26, 0.95);
  gap: 24px;
  position: fixed;
  top: 70px;
  width: 100vw;
  right: 0;
  bottom: 0;
  transform: translateX(${props => (props.opened ? 0 : '100vw')});
  transition: 0.3s ease;
  z-index: 2;
  padding: 8px 24px 24px 24px;
  height: 100vh;
  color: white;

  @media (min-width: 1024px) {
    display: none;
  }
`
