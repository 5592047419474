import { ReactComponent as Account } from '../../../assets/icons/Faq/account.svg'
import { ReactComponent as Affiliation } from '../../../assets/icons/Faq/affiliationIcon.svg'
import { ReactComponent as Bank } from '../../../assets/icons/Faq/bank.svg'
import { ReactComponent as Buy } from '../../../assets/icons/Faq/buy.svg'
import { ReactComponent as Collaborators } from '../../../assets/icons/Faq/collaborators.svg'
import { ReactComponent as MembersArea } from '../../../assets/icons/Faq/members-area.svg'
import { ReactComponent as Plans } from '../../../assets/icons/Faq/plans.svg'
import { ReactComponent as Questions } from '../../../assets/icons/Faq/questions.svg'
import { ReactComponent as Refound } from '../../../assets/icons/Faq/refound.svg'
import { ReactComponent as Search } from '../../../assets/icons/Faq/search.svg'

export const sectorsMap = [
  {
    id: 'account',
    label: 'Conta',
    topico: '12 tópicos',
    icon: <Account />
  },
  {
    id: 'affiliation',
    label: 'Afiliação',
    topico: '12 tópicos',
    icon: <Affiliation />
  },
  {
    id: 'collaborators',
    label: 'Colaboradores',
    topico: '12 tópicos',
    icon: <Collaborators />
  },
  {
    id: 'digital-bank',
    label: 'Digital Bank',
    topics: 1,
    topico: '12 tópicos',
    isActive: true,
    icon: <Bank />
  },
  {
    id: 'frequently',
    label: 'Dúvidas Frequentes',
    topico: '12 tópicos',
    icon: <Questions />
  },
  {
    id: 'members-area',
    label: 'Área de Membros',
    topico: '12 tópicos',
    icon: <MembersArea />
  },
  {
    id: 'pixel',
    label: 'Pixel',
    topico: '12 tópicos',
    icon: <Search />
  },
  {
    id: 'products-plans',
    label: 'Produtos e Planos',
    topico: '12 tópicos',
    icon: <Plans />
  },
  {
    id: 'purchases',
    label: 'Acessar minha compra',
    topico: '12 tópicos',
    icon: <Buy />
  },
  {
    id: 'refound',
    label: 'Reembolso',
    topico: '12 tópicos',
    icon: <Refound />
  }
]
