import styled from 'styled-components'

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding: 32px 0;
  padding: 0 10vw;
  margin-top: 120px;
  margin-bottom: 40px;
`
export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background: var(--naval);
  overflow-x: auto;
  justify-content: space-between;
`

export const BackButton = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  color: var(--seccondary);
  font-size: 14px;
  font-weight: 500;
  align-items: center;

  > svg > path {
    stroke: var(--seccondary);
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h1 {
    font-size: 26px;
    color: var(--white);
    font-weight: 800;
  }

  > p {
    font-size: 18px;
    color: var(--white);
    font-weight: 500;
  }

  > img {
    width: 100%;
    height: 296px;
    border-radius: 5px;
    object-fit: cover;
  }
  > video {
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
`
