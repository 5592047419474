import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden !important;
  max-width: 100vw;
`

export const ContentGradient = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;

  background: rgb(255, 255, 255, 1);
  background: linear-gradient(
    180deg,
    rgba(26, 26, 26, 1) 7%,
    rgba(0, 45, 89, 1) 18%,
    rgba(24, 101, 150, 1) 47%,
    rgba(54, 169, 225, 1) 64%,
    rgba(255, 255, 255, 1) 96%
  );
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 80px;
`

export const ContentSeccondary = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
`

export const CardWrap = styled.div`
  display: flex;
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  padding: 32px 32px 0;
  color: white;
  gap: 32px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 32px 32px 0 !important;
  }

  @media (max-width: 600px) {
    padding: 24px 26px 0 !important;
  }
`

export const PrimaryContent = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  height: 70vh;
  padding-top: 32px;
  gap: 64px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const MainImage = styled.div`
  width: 100%;
  overflow: visible;
  position: absolute;
  height: 100%;
  display: flex;
  right: 0;
  bottom: 0;
  justify-content: flex-end;
  /* pointer-events: none; */
  opacity: 0.4;
  mix-blend-mode: overlay;

  @media (max-width: 1024px) {
    right: -10%;
  }

  @media (max-width: 769px) {
    right: -3%;
    height: 100%;
    bottom: 10%;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background-color: black;
  padding: 24px 26px;
  z-index: 3;
  box-shadow: 0px 5px 15px 0px #00000026;
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* width: 100%; */
  flex: 1;
  position: relative;
  align-items: center;

  > svg {
    position: absolute;
    top: -50%;
    left: -50%;
    opacity: 0.5;
  }

  > h1 {
    font-size: 64px;
    color: white;
    font-weight: 700;
    z-index: 1;
    width: fit-content;
    line-height: 1.15em;
    text-align: center;

    @media (max-width: 620px) {
      font-size: 48px;
    }

    @media (max-width: 468px) {
      font-size: 38px;
    }
  }

  > span {
    font-size: 24px;
    color: white;
    font-weight: 500;

    > b {
      color: var(--primary);
    }
  }

  > p {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
    padding-top: 16px;
    max-width: 700px;
    width: 100%;
    text-align: center;

    @media (max-width: 620px) {
      font-size: 17px;
    }

    @media (max-width: 468px) {
      font-size: 14px;
    }
  }

  > a {
    font-size: 20px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    width: fit-content;
    gap: 4px;
    align-items: center;
    transition: 0.3s ease;
    margin-top: 16px;
    cursor: pointer;
    z-index: 1;
    text-decoration: none;

    > svg {
      transition: 0.3s ease;
      margin-top: 3px;
      transform: translate(0px);
    }

    &:hover {
      color: white;

      > svg {
        transform: translate(10px);
        > path {
          color: white;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const ContentCardsMosaic = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;
  justify-content: center;
`

export const ContentCards3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;
  justify-content: center;

  > div {
    max-width: calc((100% - 32px) / 2);
  }

  > div:last-child {
    max-width: 100%;
    width: 100%;
  }
`

interface DivisorProps {
  color: string
  gradient?: string
  visible?: boolean
}

export const DivisorContainer = styled.div<DivisorProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  > p {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      height: 24px;
      width: 24px;
    }

    &::after {
      content: '';
      position: absolute;
      background: ${props => props.color};
      width: 40px;
      height: 40px;
      pointer-events: none;
      filter: blur(18px);
    }
  }
`

export const DivisorBottomContainer = styled.div<DivisorProps>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  height: 200px;

  > p {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      height: 24px;
      width: 24px;
    }

    &::after {
      content: '';
      position: absolute;
      background: ${props => props.color};
      width: 40px;
      height: 40px;
      pointer-events: none;
      filter: blur(18px);
    }
  }
`

export const Divisor = styled.div<DivisorProps>`
  display: flex;
  width: 4px;
  background: ${props => props.color};
  background: ${props => props.gradient};
  border-radius: 100px;

  transition: 0.4s ease;
  height: ${props => (props.visible ? '100%' : 0)};
  opacity: ${props => (props.visible ? 1 : 0)};

  @media (max-width: 900px) {
    transition: 0.6s ease;
  }
`

export const Row = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
`

export const ColumnImage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  margin-top: -32px;
`

interface TextsProps {
  backgroundColor?: string
  color?: string
}

export const TextsContainer = styled.div<TextsProps>`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding-bottom: 64px;

  > span {
    font-size: 16px;
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    font-weight: 600;
    margin-bottom: -12px;
    padding: 2px 12px;
    border-radius: 50px;
  }

  > h1 {
    font-size: 44px;
    color: #3c4042;
    font-weight: 700;
    line-height: 1.15;

    @media (max-width: 600px) {
      font-size: 32px;
    }
  }

  > p {
    font-size: 18px;
    color: #848484;
    font-weight: 300;
  }

  > a {
    font-size: 20px;
    color: var(--white);
    font-weight: 600;
    cursor: pointer;
    display: flex;
    width: fit-content;
    gap: 4px;
    align-items: center;
    transition: 0.3s ease;
    text-decoration: none;

    > svg {
      transition: 0.3s ease;
      transform: translate(0px);
    }

    &:hover {
      color: var(--seccondary);

      > svg {
        transform: translate(10px);
        > path {
          color: var(--seccondary);
        }
      }
    }
  }
`

export const BannerContainer = styled.nav`
  > img {
    max-width: 100%;
    max-height: 600px;
  }
`

export const BannerContainerAffiliates = styled.nav`
  max-width: 100%;
  max-height: 450px;
  margin: 8px 0;
  position: relative;

  > img {
    height: 100%;
    box-shadow: var(--shadow-banners);
  }
`

export const GridContainer = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  flex-direction: column;
`

export const Column = styled.div`
  display: flex;
  gap: 32px;
  width: 100%;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  > div:first-child {
    justify-content: space-between;

    > div {
      padding: 36px 32px 64px;

      @media (max-width: 600px) {
        padding: 36px 32px 24px;
      }
    }

    > div > a {
      transition: 0.3s ease;

      > svg {
        transition: 0.3s ease;
        transform: translate(0px);
      }

      &:hover {
        color: rgb(15, 211, 173);

        > svg {
          transform: translate(10px);
          > path {
            color: rgb(15, 211, 173);
          }
        }
      }
    }
  }

  > div:last-child > div > a {
    transition: 0.3s ease;

    > svg {
      transition: 0.3s ease;
      transform: translate(0px);
    }
    &:hover {
      color: rgba(158, 107, 211, 1);

      > svg {
        transform: translate(10px);
        > path {
          color: rgba(158, 107, 211, 1);
        }
      }
    }
  }
`

interface CardGridFirstProps {
  visible?: boolean
}

export const CardGridFirst = styled.div<CardGridFirstProps>`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid var(--inputs);
  flex-direction: column;
  overflow: hidden;

  > div {
    display: flex;
    gap: 32px;
    flex-direction: column;
    justify-content: space-between;
    padding: 32px;

    > p {
      font-size: 24px;
      color: var(--placeholder);
      font-weight: 600;

      > b {
        color: var(--white);
      }

      @media (max-width: 769px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    > a {
      font-size: 20px;
      color: var(--white);
      font-weight: 600;
      cursor: pointer;
      display: flex;
      width: fit-content;
      gap: 4px;
      align-items: center;
      transition: 0.3s ease;
      text-decoration: none;

      > svg {
        transition: 0.3s ease;
        transform: translate(0px);
      }

      &:hover {
        color: var(--primary);

        > svg {
          transform: translate(10px);
          > path {
            color: var(--primary);
          }
        }
      }

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    @media (max-width: 600px) {
      padding: 24px 26px;
    }
  }

  > section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    > img {
      max-height: 380px;
      margin-bottom: -130px;
      box-shadow: var(--shadow-banners);

      @media (max-width: 860px) {
        max-height: 100%;
        max-height: 300px;
        margin-bottom: -4px;
      }

      @media (max-width: 720px) {
        max-height: 250px;
      }

      @media (max-width: 600px) {
        max-height: 180px;
      }
      @media (max-width: 460px) {
        max-height: 150px;
      }
      @media (max-width: 400px) {
        max-height: 120px;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 23.1%;
      z-index: 1;

      transition: 0.5s ease;
      transform: translateX(${props => (props.visible ? 0 : '-100px')});
      opacity: ${props => (props.visible ? 1 : 0)};

      &:before {
        content: '';
        background-color: var(--primary);
        height: 40px;
        width: 460px;
        position: absolute;
        bottom: -170%;
        left: 20%;
        filter: blur(100px);
        z-index: -1;

        @media (max-width: 900px) {
          bottom: 0;
          height: 5px;
        }
      }

      > img {
        border-radius: 5px;
        height: 72px;
        box-shadow: var(--shadow-banners);

        @media (max-width: 860px) {
          max-width: 100%;
          width: 90%;
          height: 60px;
        }

        @media (max-width: 720px) {
          height: 50px;
        }

        @media (max-width: 600px) {
          height: 35px;
        }

        @media (max-width: 460px) {
          height: 30px;
        }
        @media (max-width: 400px) {
          height: 25px;
        }
      }

      > nav {
        display: flex;
        position: absolute;
        bottom: -20px;
        z-index: 2;

        > img {
          border-radius: 5px;
          height: 60px;

          @media (max-width: 860px) {
            max-width: 100%;
            width: 100%;
            height: 50px;
          }

          @media (max-width: 720px) {
            height: 40px;
          }

          @media (max-width: 600px) {
            height: 25px;
            object-fit: contain;
          }

          @media (max-width: 460px) {
            height: 20px;
          }
          @media (max-width: 400px) {
            height: 15px;
          }
        }

        @media (max-width: 600px) {
          bottom: -10px;
        }
      }

      @media (max-width: 900px) {
        transition: 1s ease;
      }

      @media (max-width: 860px) {
        bottom: 49.5%;
      }
    }
  }
`

interface CardGridProps {
  visible?: boolean
  isBlured?: boolean
}

export const CardGrid = styled.div<CardGridProps>`
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 5px;
  border: 1px solid var(--inputs);
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  > div {
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding: 32px;

    > p {
      font-size: 24px;
      color: var(--placeholder);
      font-weight: 600;

      > b {
        color: var(--white);
      }

      @media (max-width: 769px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    > a {
      font-size: 20px;
      color: var(--white);
      font-weight: 600;
      cursor: pointer;
      display: flex;
      width: fit-content;
      gap: 4px;
      align-items: center;
      cursor: pointer;
      text-decoration: none;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    @media (max-width: 600px) {
      padding: 24px 26px;
    }
  }

  > section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -10px;

    > img {
      max-height: 350px;
      height: 100%;
      margin-right: 12em;
      transition: 0.6s ease;
      transform: translateY(${props => (props.visible ? 0 : '100px')});
      opacity: ${props => (props.visible ? 1 : 0)};
      box-shadow: var(--shadow-banners);
      border-radius: 14px;
      filter: blur(${props => (props.isBlured ? '4px' : '0')});
      transition-delay: ${props => (props.isBlured ? '0.3s' : '0')};

      @media (max-width: 800px) {
        margin-right: 10em;
      }

      @media (max-width: 500px) {
        margin-right: 9em;
      }
      @media (max-width: 400px) {
        margin-right: 8em;
        max-height: 300px;
      }

      @media (max-width: 820px) {
        max-width: 100%;
      }

      @media (max-width: 900px) {
        transition: 1s ease;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 19.5%;
      z-index: 1;
      transition: 0.6s ease;
      transform: translateY(${props => (props.visible ? 0 : '100px')});
      opacity: ${props => (props.visible ? 1 : 0)};
      transition-delay: 0.3s;

      &:before {
        content: '';
        background-color: var(--primary);
        height: 300px;
        width: 150px;
        position: absolute;
        bottom: 0;
        left: 5%;
        filter: blur(60px);
        z-index: -1;

        @media (max-width: 600px) {
          width: 120px;
        }
      }

      > img {
        height: 100%;
        max-height: 400px;

        border-radius: 18px;
        box-shadow: var(--shadow-banners);
      }
      @media (max-width: 900px) {
        transition: 1s ease;
      }
      @media (max-width: 800px) {
        right: 17.5%;
      }
      @media (max-width: 700px) {
        right: 15.5%;
      }
      @media (max-width: 600px) {
        right: 13.5%;
      }
      @media (max-width: 500px) {
        right: 9.5%;
      }
      @media (max-width: 400px) {
        right: 7.5%;
        max-height: 360px;
      }
    }

    @media (max-width: 1024px) {
      width: initial;
    }

    @media (max-width: 600px) {
      margin-top: 64px;
    }
  }

  > nav {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    > img {
      max-height: 400px;
      height: 100%;
      max-width: 500px;
      transition: 0.5s ease;
      transition-delay: ${props => (props.isBlured ? '0.3s' : '0')};
      transform: translateY(${props => (props.visible ? 0 : '100px')});
      opacity: ${props => (props.visible ? 1 : 0)};
      filter: blur(${props => (props.isBlured ? '4px' : '0')});
      box-shadow: var(--shadow-banners);
      border-radius: 8px;

      @media (max-width: 820px) {
        max-width: 100%;
      }

      @media (max-width: 1160px) {
        padding: 0 14px;
        max-width: 450px;
      }

      @media (max-width: 535px) {
        max-width: 400px;
      }

      @media (max-width: 455px) {
        max-width: 350px;
      }

      @media (max-width: 413px) {
        max-width: 280px;
      }
    }

    > div {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -48px;
      z-index: 1;

      transition: 0.5s ease;
      transition-delay: ${props => (props.isBlured ? '0.3s' : '0')};
      transform: translateY(${props => (props.visible ? 0 : '100px')});
      opacity: ${props => (props.visible ? 1 : 0)};
      filter: blur(${props => (props.isBlured ? '4px' : '0')});

      > img {
        border-radius: 5px;
        height: 75px;
        box-shadow: var(--shadow-banners);

        @media (max-width: 1160px) {
          height: 65px;
        }

        @media (max-width: 535px) {
          height: 60px;
        }

        @media (max-width: 455px) {
          height: 55px;
        }

        @media (max-width: 413px) {
          height: 50px;
        }
      }
    }

    > section {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -12px;
      z-index: 2;

      transition: 0.6s ease;
      transition-delay: 0.5s;
      transform: translateX(${props => (props.visible ? 0 : '100px')});
      opacity: ${props => (props.visible ? 1 : 0)};

      > img {
        border-radius: 5px;
        height: 59px;
        box-shadow: var(--shadow-banners);

        @media (max-width: 1160px) {
          height: 54px;
        }

        @media (max-width: 535px) {
          height: 49px;
        }

        @media (max-width: 455px) {
          height: 44px;
        }

        @media (max-width: 413px) {
          height: 39px;
        }
      }
    }
  }
`

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  gap: 64px;
  padding: 128px 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(247, 247, 247, 1) 66%
  );
  align-items: center;

  @media (max-width: 600px) {
    padding: 48px 0;
  }
`

export const WrapperFaq = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  padding: 0 32px;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 0 24px;
  }
`

export const BackgroundOverlapContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 0;
  right: -28%;
  top: -50%;
  /* pointer-events: none; */
  opacity: 0.6;

  > svg {
    height: 600px;

    @media (max-width: 900px) {
      height: 400px;
    }
  }

  > svg > g > path {
    stroke: var(--placeholder);
  }

  > svg > g > ellipse {
    fill: var(--placeholder);
  }

  @media (max-width: 900px) {
    bottom: -120px;
    top: auto;
  }

  @media (max-width: 480px) {
    right: -40%;
  }

  @media (max-width: 426px) {
    right: -55%;
  }
`
