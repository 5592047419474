import React, { useRef } from 'react'
import StudentsBanner from '../../../../assets/images/LandingPageV2/studentsBanners/main-banner-students.png'
import FirstOverlapStudents from '../../../../assets/images/LandingPageV2/studentsBanners/first-overlap-students.png'
import SecondOverlapStudents from '../../../../assets/images/LandingPageV2/studentsBanners/second-overlap-students.png'
import ThirdOverlapStudents from '../../../../assets/images/LandingPageV2/studentsBanners/third-overlap-students.png'
import * as S from './styles'
import { ReactComponent as BackgroundOverlap } from '../../../../assets/images/LandingPageV2/background-overlap.svg'

import { getElementVisibility } from '../..'
interface StudentsProps {
  scrollPosition: number
}

const Students: React.FC<StudentsProps> = ({ scrollPosition }) => {
  const imagesRef: {
    mainBanner: React.MutableRefObject<HTMLDivElement | null>
    firstOverlap: React.MutableRefObject<HTMLDivElement | null>
    secondOverlap: React.MutableRefObject<HTMLDivElement | null>
    thirdOverlap: React.MutableRefObject<HTMLDivElement | null>
  } = {
    mainBanner: useRef(null),
    firstOverlap: useRef(null),
    secondOverlap: useRef(null),
    thirdOverlap: useRef(null)
  }
  return (
    <S.BannerContainerStudents
      ref={imagesRef.mainBanner}
      visible={getElementVisibility(
        imagesRef.secondOverlap.current,
        scrollPosition
      )}
    >
      <S.BackgroundOverlapContainer>
        <BackgroundOverlap />
      </S.BackgroundOverlapContainer>
      <img src={StudentsBanner} />
      <S.FirstOverlap
        ref={imagesRef.firstOverlap}
        visible={getElementVisibility(
          imagesRef.firstOverlap.current,
          scrollPosition
        )}
      >
        <img src={FirstOverlapStudents} />
      </S.FirstOverlap>
      <S.SecondOverlap
        ref={imagesRef.secondOverlap}
        visible={getElementVisibility(
          imagesRef.secondOverlap.current,
          scrollPosition
        )}
      >
        <img src={SecondOverlapStudents} />
      </S.SecondOverlap>
      <S.ThirdOverlap
        ref={imagesRef.thirdOverlap}
        visible={getElementVisibility(
          imagesRef.thirdOverlap.current,
          scrollPosition
        )}
      >
        <img src={ThirdOverlapStudents} />
      </S.ThirdOverlap>
    </S.BannerContainerStudents>
  )
}

export default Students
