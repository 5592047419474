import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  justify-content: center;
`

export const Wrapper = styled.div`
  display: flex;
  max-width: 980px;
  width: 100%;
  padding: 32px 0;
  flex-direction: column;
  gap: 32px;

  @media (max-width: 1024px) {
    padding: 24px 26px;
  }
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 868px;

  @media (max-width: 870px) {
    flex-direction: column;
    justify-content: center;
    gap: 32px;
  }
`

export const Column = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;
  color: var(--white);

  > h4 {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
  }

  > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    white-space: nowrap;
  }

  > a {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    color: var(--white);
  }

  > button {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    gap: 8px;
    outline: 1px solid var(--primary);
    border-radius: 5px;
    padding: 9px;
    align-items: center;
    justify-content: center;
    color: var(--white);
    max-width: 123px;
    cursor: pointer;

    > svg {
      @media (max-width: 380px) {
        display: none;
      }
    }

    &:hover {
      background: var(--primary);
      transition: 0.3s ease;

      > svg > path {
        fill: var(--white);
      }
    }

    @media (max-width: 400px) {
      font-size: 12px;
    }
  }

  @media (max-width: 640px) {
    width: 100% !important;
  }
`

export const ColumnSupport = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;
  color: var(--white);
  align-items: center;
  justify-content: center;

  > a {
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    color: white;
    background-color: var(--primary);
    padding: 8px 16px;
    border-radius: 4px;
    width: 100%;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  @media (max-width: 640px) {
    width: 100% !important;
  }
`

export const ColumnContent = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;
  color: var(--white);

  @media (max-width: 640px) {
    width: 90% !important;
  }
`

export const ColumnButton = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;
  color: var(--white);
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  > svg {
    height: 58px;
    width: 128px;

    @media (max-width: 640px) {
      width: 66px;
      height: 30px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 14px;
    color: var(--text);
  }

  @media (max-width: 1024px) {
    width: 40%;
  }

  @media (max-width: 640px) {
    justify-content: flex-start;
    width: 100%;
  }
`

export const DivisorContainer = styled.div`
  display: flex;
  width: 100%;
`

export const Divisor = styled.div`
  display: flex;
  width: 100%;
  max-width: 868px;
  border-radius: 1px;
  height: 1px;
  background-color: var(--primary);
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--white);
  display: flex;
  justify-content: center;
`

export const RowContent = styled.div`
  display: flex;
  width: 100%;
  gap: 48px;
  justify-content: flex-end;
  padding-left: 16px;

  @media (max-width: 700px) {
    gap: 32px;
  }

  @media (max-width: 640px) {
    gap: 16px;
    justify-content: flex-start;
  }

  @media (max-width: 640px) {
    flex-wrap: wrap;
    padding-left: 0;

    > div {
      max-width: calc((100% - 16px) / 2);
    }
  }
  @media (max-width: 440px) {
    flex-wrap: wrap;

    > div {
      max-width: 100%;
      width: 100% !important;
    }
  }
`
