import styled from 'styled-components'

interface BannerProps {
  visible?: boolean
}

export const BannerContainerStudents = styled.nav<BannerProps>`
  max-width: 100%;
  max-height: 500px;
  margin: 8px 0;
  position: relative;

  > img {
    height: 500px;
    filter: blur(${props => (props.visible ? '4px' : 0)});
    transition: 0.3s ease;
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    box-shadow: var(--shadow-banners);
  }

  @media (max-width: 900px) {
    max-height: 100%;
    transition: 1s ease;
    > img {
      height: 100%;
    }
  }
`

export const FirstOverlap = styled.div<BannerProps>`
  display: flex;
  position: absolute;
  top: -24px;
  right: -10%;
  z-index: 1;
  transition: 0.5s ease;
  transform: translateX(${props => (props.visible ? 0 : '100px')});
  opacity: ${props => (props.visible ? 1 : 0)};
  transition-delay: 0.2s;

  &:before {
    content: '';
    background-color: var(--primary);
    height: 50px;
    width: 120px;
    position: absolute;
    top: 30%;
    left: 2%;
    filter: blur(80px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 20px;
      width: 90px;
      left: 20px;
    }

    @media (max-width: 480px) {
      height: 10px;
      width: 90px;
      top: 40%;
    }
  }

  > img {
    border-radius: 5px;
    box-shadow: var(--shadow-banners);
  }

  @media (max-width: 1513px) {
    right: -5%;
  }

  @media (max-width: 1405px) {
    right: -2%;
  }

  @media (max-width: 900px) {
    transition: 1s ease;
    > img {
      height: 90px;
    }
  }

  @media (max-width: 800px) {
    > img {
      height: 70px;
    }
  }

  @media (max-width: 700px) {
    > img {
      height: 50px;
    }
  }

  @media (max-width: 550px) {
    > img {
      height: 40px;
    }
  }
`

export const SecondOverlap = styled.div<BannerProps>`
  position: absolute;
  top: 8%;
  right: -20%;
  z-index: 2;
  transform: translateY(${props => (props.visible ? 0 : '50px')});
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 0.5s ease;
  transition-delay: 0.4s;

  &:before {
    content: '';
    background-color: var(--primary);
    height: 35px;
    width: 200px;
    position: absolute;
    top: 5%;
    left: -5%;
    filter: blur(80px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 20px;
      width: 100px;
      left: 20px;
    }
  }

  > img {
    border-radius: 5px;
    height: 80px;
    box-shadow: var(--shadow-banners);
  }

  @media (max-width: 1789px) {
    right: -15%;
  }

  @media (max-width: 1629px) {
    right: -8%;
  }

  @media (max-width: 1500px) {
    right: 0;
  }

  @media (max-width: 900px) {
    transition: 1s ease;
    > img {
      height: 60px;
    }
  }

  @media (max-width: 800px) {
    > img {
      height: 50px;
    }
  }

  @media (max-width: 700px) {
    top: 4%;
    > img {
      height: 30px;
    }
  }

  @media (max-width: 550px) {
    > img {
      height: 30px;
    }
  }
`

export const ThirdOverlap = styled.div<BannerProps>`
  position: absolute;
  bottom: -5%;
  right: -20%;
  z-index: 2;
  transition: 0.5s ease;
  transform: translateY(${props => (props.visible ? 0 : '50px')});
  opacity: ${props => (props.visible ? 1 : 0)};

  &:before {
    content: '';
    background-color: var(--primary);
    height: 20px;
    width: 200px;
    position: absolute;
    top: 30%;
    left: -5%;
    filter: blur(50px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 15px;
      width: 100px;
      left: 20px;
    }
  }

  > img {
    border-radius: 5px;
    box-shadow: var(--shadow-banners);
  }

  @media (max-width: 1761px) {
    right: -15%;
  }

  @media (max-width: 1645px) {
    right: -8%;
  }

  @media (max-width: 1500px) {
    right: 0;
  }

  @media (max-width: 900px) {
    transition: 1s ease;
    > img {
      height: 60px;
    }
  }

  @media (max-width: 800px) {
    > img {
      height: 50px;
    }
  }

  @media (max-width: 550px) {
    > img {
      height: 30px;
    }
  }
`

export const BackgroundOverlapContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 0;
  right: -35%;
  bottom: -70%;
  pointer-events: none;
  opacity: 0.9;

  /* transform: rotate(330deg); */

  > svg {
    height: 700px;
  }

  > svg > g > rect {
    stroke: var(--placeholder);
  }
`
