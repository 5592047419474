import React, { useContext, useState, createContext } from 'react'
import AlertBallon from '../components/AlertBallon'
import Loading from '../components/Loading'

interface ContextProps {
  isLoading: boolean
  setIsLoading: any
  showAlert: (
    type: string,
    title: string,
    content: string,
    percentage?: number
  ) => void
  alertError: any
  stage: any
  setStage: any
  setEmail: any
  email: any
  code: any
  setCode: any
  modalStatic: boolean
  setModalStatic: React.Dispatch<React.SetStateAction<boolean>>
}

export const GeneralContext = createContext<ContextProps>({} as ContextProps)

export const GeneralProvider: React.FC = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [stage, setStage] = useState(1)
  const [email, setEmail] = useState<string>('')
  const [code, setCode] = useState<string>('')
  // TO-DO: Remove static modal
  const [modalStatic, setModalStatic] = useState(false)

  const [alertBaloonData, setAlertbaloonData] = useState({
    opened: false,
    type: 'error',
    title: 'Ocorreu um erro!',
    content: 'Tente novamente mais tarde.',
    progressPercentage: 0
  })

  const closeAlert = () => {
    setAlertbaloonData({
      ...alertBaloonData,
      opened: false
    })
  }

  const showAlert = (
    type: string,
    title: string,
    content: string,
    percentage?: number
  ) => {
    setAlertbaloonData({
      opened: true,
      type,
      title,
      content,
      progressPercentage: percentage || 0
    })
    setTimeout(closeAlert, 4000)
  }

  const alertError = (e: any) => {
    showAlert(
      'error',
      'Error',
      e?.response?.data?.message ||
        e?.response?.data?.errors?.[0] ||
        e?.response?.data?.body ||
        e?.message ||
        'We had internal problems trying to perform this operation. Please try again in a moment.'
    )
  }

  return (
    <GeneralContext.Provider
      value={{
        isLoading,
        setIsLoading,
        showAlert,
        alertError,
        stage,
        setStage,
        setEmail,
        email,
        code,
        setCode,
        modalStatic,
        setModalStatic
      }}
    >
      {children}
      <AlertBallon {...alertBaloonData} closeFunction={closeAlert} />
      {isLoading && <Loading />}
    </GeneralContext.Provider>
  )
}

export const useGeneral = (): ContextProps => useContext(GeneralContext)
