/* eslint-disable no-undef */
export const dataToHTML = (builderComponents: any[]): JSX.Element[] => {
  const result: JSX.Element[] = []
  builderComponents.forEach((item: any, i) => {
    if (item.type === 'Text') {
      const styles = {
        color: item?.style?.color ? item?.style?.color : '¨#FBFFFC',
        fontFamily: item?.style?.fontFamily
          ? item?.style?.fontFamily
          : 'Montserrat',
        fontSize: item?.style?.fontSize ? item?.style?.fontSize : '12',
        fontStyle: item?.style?.fontStyle ? 'italic' : '',
        fontWeight: item?.style?.fontWeight ? 'bold' : '',
        textDecoration: item?.style?.textDecoration ? 'underline' : '',
        textAlign: item?.style?.textAlign ? item?.style?.textAlign : 'start'
      }

      result.push(<p style={styles}>{item?.label ? item?.label : ''}</p>)
    } else if (item.type === 'Image' && item?.url) {
      result.push(<img src={item?.url}></img>)
    } else if (item.type === 'Video' && item?.url) {
      result.push(
        <video controls>
          <source src={item?.url}></source>
        </video>
      )
    }
  })
  return result
}

export const limitNumber = (
  value: number,
  minimum: number,
  limit: number
): number => {
  if (value <= minimum) {
    return minimum
  }
  if (value >= limit) {
    return limit
  }
  return value
}

export const linearEquation = (
  ya: number,
  yb: number,
  xa: number,
  xb: number
): ((value: number) => number) => {
  const angular = (ya - yb) / (xa - xb)
  return (value: number) => angular * (value - xa) + ya
}
