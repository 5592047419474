import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import { ReactComponent as Screen } from '../../assets/icons/LandingPage/screen.svg'
import * as S from './styles'
// import WrapCards from './Sections/WrapCards'
import { IoArrowForward } from 'react-icons/io5'
import FAQ from '../LandingPages/Components/FAQ'
import MainBanner from '../../assets/images/LandingPageV2/firstSection/main-background.png'
import BuilderBanner from '../../assets/images/LandingPageV2/checkoutBuilderBanners/banner-checkout-builder.png'
import OverlapCheckoutBuilder from '../../assets/images/LandingPageV2/checkoutBuilderBanners/overlap-checkout-builder.png'
import SecondOverlapCheckoutBuilder from '../../assets/images/LandingPageV2/checkoutBuilderBanners/second-overlap-checkout-builder.png'

// mobile
import MobileBanner from '../../assets/images/LandingPageV2/mobile/main-mobile.png'
import OverlapMobileBanner from '../../assets/images/LandingPageV2/mobile/overlap-mobile.png'

// class
import MainBannerClass from '../../assets/images/LandingPageV2/class/main-banner-class.png'
import FirstOverlapClass from '../../assets/images/LandingPageV2/class/first-overlap-class.png'
import SecondOverlapClass from '../../assets/images/LandingPageV2/class/second-overlap-class.png'

import { ReactComponent as BackgroundOverlap } from '../../assets/images/LandingPageV2/second-background-overlap.svg'
// import { ReactComponent as Memphis } from '../../assets/images/LandingPageV2/memphisHome.svg'
import Producer from './Sections/Producer'
import Affiliates from './Sections/Affiliates'
import Students from './Sections/Students'
import Footer from '../LandingPages/Components/Footer'

export const getElementVisibility = (
  ref: HTMLDivElement | null,
  _: number,
  limitRate?: number
): boolean => {
  const actualRef = ref
  if (actualRef) {
    const limit = window.innerHeight * (limitRate || 0.6)
    if (ref.getBoundingClientRect().top < limit) {
      return true
    }
  }
  return false
}

const LandingPageV2: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  // const [windowHeight, setWindowHeight] = useState(0)
  const imagesRef: {
    checkoutBuilder: React.MutableRefObject<HTMLDivElement | null>
    mobileMain: React.MutableRefObject<HTMLDivElement | null>
    mobileOverlap: React.MutableRefObject<HTMLDivElement | null>
    classMain: React.MutableRefObject<HTMLDivElement | null>
    classFirstOverlap: React.MutableRefObject<HTMLDivElement | null>
    classSecondOverlap: React.MutableRefObject<HTMLDivElement | null>
    divisorProducer1: React.MutableRefObject<HTMLDivElement | null>
    divisorProducer2: React.MutableRefObject<HTMLDivElement | null>
    divisorAffiliates1: React.MutableRefObject<HTMLDivElement | null>
    divisorAffiliates2: React.MutableRefObject<HTMLDivElement | null>
    divisorStudents1: React.MutableRefObject<HTMLDivElement | null>
    divisorStudents2: React.MutableRefObject<HTMLDivElement | null>
  } = {
    checkoutBuilder: useRef(null),
    mobileMain: useRef(null),
    mobileOverlap: useRef(null),
    classMain: useRef(null),
    classFirstOverlap: useRef(null),
    classSecondOverlap: useRef(null),
    divisorProducer1: useRef(null),
    divisorProducer2: useRef(null),
    divisorAffiliates1: useRef(null),
    divisorAffiliates2: useRef(null),
    divisorStudents1: useRef(null),
    divisorStudents2: useRef(null)
  }

  useEffect(() => {
    const handleGetHeight = () => {
      // setWindowHeight(window.innerHeight)
    }
    const handleGetScroll = () => {
      setScrollPosition(window.scrollY)
    }

    window.addEventListener('resize', handleGetHeight)
    document.addEventListener('scroll', handleGetScroll)

    return () => {
      window.removeEventListener('resize', handleGetHeight)
      document.removeEventListener('scroll', handleGetScroll)
    }
  }, [])

  return (
    <S.Container id="home">
      <S.ContentGradient>
        <S.Content>
          <S.Wrapper>
            <Header />
            <S.PrimaryContent>
              <S.Texts>
                {/* <Memphis /> */}
                <h1>
                  A revolução do <br />
                  Print on Demand
                </h1>

                <p>
                  Explore o marketing digital com as ferramentas Keed,
                  impulsionando vendas e elevando seu desempenho para resultados
                  únicos!
                </p>
                <a href={process.env.REACT_APP_WIKI_LOGIN} target="_blank">
                  Começar agora <IoArrowForward color="white" />
                </a>
              </S.Texts>
            </S.PrimaryContent>
          </S.Wrapper>
        </S.Content>
        <S.MainImage>
          <img src={MainBanner} />
        </S.MainImage>
      </S.ContentGradient>

      <S.ContentSeccondary style={{ paddingTop: 48 }} id="features">
        <S.Wrapper>
          <S.Row>
            <S.DivisorContainer color="rgba(47,177,216,0.5)">
              <p>
                <Screen />
              </p>
              <S.Divisor
                visible={getElementVisibility(
                  imagesRef.divisorProducer1.current,
                  scrollPosition
                )}
                ref={imagesRef.divisorProducer1}
                color="rgba(47,177,216,0.5)"
                gradient="background: rgb(54,169,225);
                background: linear-gradient(180deg, rgba(54,169,225,0) 0%, rgba(54,169,225,1) 20%, rgba(54,169,225,1) 80%, rgba(54,169,225,0) 100%);"
              />
            </S.DivisorContainer>
            <S.TextsContainer
              backgroundColor="rgba(54, 169, 225, 0.15)"
              color="var(--primary)"
            >
              <span>Para produtores</span>
              <h1>
                Crie, venda e alcance o sucesso personalizando cada detalhe.
              </h1>

              <p>
                Desfrute de total autonomia para personalizar cada aspecto do
                seu produto, estratégias de venda, dados de afiliação, cupons e
                muito mais em nossa plataforma.
              </p>
            </S.TextsContainer>
          </S.Row>
          <S.ColumnImage>
            {/* Producer content */}
            <Producer scrollPosition={scrollPosition} />

            <S.DivisorBottomContainer
              color="rgba(47,177,216,0.5)"
              style={{ width: 24 }}
            >
              <S.Divisor
                visible={getElementVisibility(
                  imagesRef.divisorProducer2.current,
                  scrollPosition
                )}
                ref={imagesRef.divisorProducer2}
                color="rgba(47,177,216,0.5)"
                gradient="linear-gradient(180deg, rgba(54,169,225,0) 0%, rgba(47,177,216,1) 20%, rgba(35,190,199,1) 50%, rgba(21,204,181,1) 80%, rgba(15,211,173,0) 100%)"
              />
            </S.DivisorBottomContainer>
          </S.ColumnImage>

          <S.Row>
            <S.DivisorContainer color="rgba(15,211,173, 0.5)">
              <p>
                <Screen />
              </p>
              <S.Divisor
                visible={getElementVisibility(
                  imagesRef.divisorAffiliates1.current,
                  scrollPosition
                )}
                ref={imagesRef.divisorAffiliates1}
                color="rgb(15,211,173)"
                gradient="linear-gradient(180deg, rgba(15,211,173,0) 0%, rgba(15,211,173,1) 20%, rgba(15,211,173,1) 80%, rgba(15,211,173,0) 100%)"
              />
            </S.DivisorContainer>
            <S.TextsContainer
              backgroundColor="rgba(15,211,173, 0.15)"
              color="rgb(15,211,173)"
            >
              <span>Para afiliados</span>
              <h1>Não desenvolveu seu produto? Nós temos a solução!</h1>

              <p>
                Venda sem criar produtos na Keed! Torne-se afiliado, escolha
                produtos do mercado digital e ganhe comissões. Facilitamos sua
                jornada conectando você a oportunidades lucrativas!
              </p>
            </S.TextsContainer>
          </S.Row>
          <S.ColumnImage>
            <Affiliates scrollPosition={scrollPosition} />
            <S.BackgroundOverlapContainer>
              <BackgroundOverlap />
            </S.BackgroundOverlapContainer>
            <S.DivisorBottomContainer
              color="rgba(15,211,173, 0.5)"
              style={{ width: 24 }}
            >
              <S.Divisor
                visible={getElementVisibility(
                  imagesRef.divisorAffiliates2.current,
                  scrollPosition
                )}
                ref={imagesRef.divisorAffiliates2}
                color="rgb(15,211,173)"
                gradient="linear-gradient(180deg, rgba(15,211,173,0) 0%, rgba(15,211,173,1) 20%, rgba(158,107,211,1) 80%, rgba(158,107,211,0) 100%)"
              />
            </S.DivisorBottomContainer>
          </S.ColumnImage>

          <S.Row>
            <S.DivisorContainer color="rgba(158,107,211,0.5)">
              <p>
                <Screen />
              </p>
              <S.Divisor
                visible={getElementVisibility(
                  imagesRef.divisorStudents1.current,
                  scrollPosition
                )}
                ref={imagesRef.divisorStudents1}
                color="rgba(158,107,211,1)"
                gradient="linear-gradient(180deg, rgba(158,107,211,0) 0%, rgba(158,107,211,1) 20%, rgba(158,107,211,1) 80%, rgba(158,107,211,0) 100%)"
              />
            </S.DivisorContainer>
            <S.TextsContainer
              backgroundColor="rgba(158,107,211,0.15)"
              color="rgba(158,107,211,1)"
            >
              <span>Para alunos</span>
              <h1>Desperte seu potencial, aprenda e evolua com a Keed!</h1>

              <p>
                Ao adquirir um produto, ganhe acesso instantâneo à plataforma
                KeedClass. Desfrute ao assistir suas aulas, avaliar o material e
                acompanhar seu progresso, tudo em um só lugar.
              </p>
            </S.TextsContainer>
          </S.Row>
          <S.ColumnImage>
            <S.BannerContainer>
              <Students scrollPosition={scrollPosition} />
            </S.BannerContainer>
          </S.ColumnImage>

          <S.GridContainer id="news">
            <S.DivisorBottomContainer
              color="rgba(158,107,211,0.5)"
              style={{ width: 24, margin: '-32px 0' }}
            >
              <S.Divisor
                visible={getElementVisibility(
                  imagesRef.divisorStudents2.current,
                  scrollPosition
                )}
                ref={imagesRef.divisorStudents2}
                color="rgba(158,107,211,1)"
                gradient="linear-gradient(180deg, rgba(158,107,211,0) 0%, rgba(158,107,211,1) 20%, rgba(158,107,211,1) 80%, rgba(158,107,211,0) 100%)"
              />
            </S.DivisorBottomContainer>
            <S.CardGridFirst
              visible={getElementVisibility(
                imagesRef.checkoutBuilder.current,
                scrollPosition
              )}
            >
              <div>
                <p>
                  <b>Checkout Builder Keed</b> é uma solução que personaliza seu
                  checkout de forma fácil e eficiente para uma experiência única
                  e adaptada às necessidades do seu negócio.
                </p>
                <a href={process.env.REACT_APP_WIKI_LOGIN}>
                  Seja o primeiro <IoArrowForward color="var(--white)" />
                </a>
              </div>
              <section ref={imagesRef.checkoutBuilder}>
                <img src={BuilderBanner} />

                <div>
                  <img src={OverlapCheckoutBuilder} />

                  <nav>
                    <img src={SecondOverlapCheckoutBuilder} />
                  </nav>
                </div>
              </section>
            </S.CardGridFirst>
            <S.Column>
              <S.CardGrid
                visible={getElementVisibility(
                  imagesRef.mobileMain.current,
                  scrollPosition
                )}
                isBlured={getElementVisibility(
                  imagesRef.mobileOverlap.current,
                  scrollPosition
                )}
              >
                <div>
                  <p>
                    <b>Keed Mobile</b> obtenha acesso imediato aos seus
                    resultados em tempo real. Acompanhe o desempenho do seu
                    negócio e analise métricas de maneira eficaz e
                    especializada.
                  </p>
                  <a style={{ opacity: 0.5, cursor: 'default' }}>
                    Em breve <IoArrowForward color="var(--white)" />
                  </a>
                </div>
                <section ref={imagesRef.mobileMain}>
                  <img src={MobileBanner} />

                  <div ref={imagesRef.mobileOverlap}>
                    <img src={OverlapMobileBanner} />
                  </div>
                </section>
              </S.CardGrid>
              <S.CardGrid
                visible={getElementVisibility(
                  imagesRef.classMain.current,
                  scrollPosition
                )}
                isBlured={getElementVisibility(
                  imagesRef.mobileOverlap.current,
                  scrollPosition
                )}
              >
                <div>
                  <p>
                    <b>Keed Class</b> simplifica a visualização de conteúdos,
                    garantindo uma navegação fácil e rápido acesso às
                    informações relevantes. Sua estrutura intuitiva proporciona
                    uma experiência personalizada e eficaz.
                  </p>
                  <a href={process.env.REACT_APP_WIKI_LOGIN}>
                    Seja o primeiro <IoArrowForward color="var(--white)" />
                  </a>
                </div>
                <nav ref={imagesRef.classMain}>
                  <img src={MainBannerClass} />

                  <div ref={imagesRef.classFirstOverlap}>
                    <img src={FirstOverlapClass} />
                  </div>

                  <section ref={imagesRef.classSecondOverlap}>
                    <img src={SecondOverlapClass} />
                  </section>
                </nav>
              </S.CardGrid>
            </S.Column>
          </S.GridContainer>

          <S.FaqContainer id="faq">
            <S.WrapperFaq>
              <S.TextsContainer
                style={{ paddingBottom: 48 }}
                backgroundColor="rgba(0, 45, 89, 0.15)"
                color="rgb(0 45 89)"
              >
                <span>FAQ</span>
                <h1>Dúvidas frequentes</h1>

                <a href={'/faq'} target="_blank">
                  Veja mais <IoArrowForward color="var(--white)" />
                </a>
              </S.TextsContainer>
              <FAQ />
            </S.WrapperFaq>
          </S.FaqContainer>
        </S.Wrapper>
      </S.ContentSeccondary>
      <Footer />
    </S.Container>
  )
}

export default LandingPageV2
