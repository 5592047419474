import styled from 'styled-components'

interface BannerProps {
  visible?: boolean
}

export const BannerContainerAffiliates = styled.nav<BannerProps>`
  max-width: 100%;
  max-height: 450px;
  margin: 8px 0;
  position: relative;
  z-index: 1;

  > img:first-child {
    height: 100%;
    filter: blur(${props => (props.visible ? '4px' : 0)});
    transition: 0.3s ease;
    box-shadow: var(--shadow-banners);

    @media (max-width: 955px) {
      height: 400px;
    }

    @media (max-width: 855px) {
      height: 350px;
    }

    @media (max-width: 755px) {
      height: 330px;
    }

    @media (max-width: 700px) {
      display: none;
    }
  }

  .responsiveBanner {
    height: 100%;
    filter: blur(${props => (props.visible ? '4px' : 0)});
    transition: 0.3s ease;
    box-shadow: var(--shadow-banners);
    object-fit: cover;
    width: 100%;

    @media (max-width: 900px) {
      transition: 1s ease;
    }

    @media (min-width: 701px) {
      display: none;
    }
  }
`

export const FirstOverlap = styled.div<BannerProps>`
  display: flex;
  position: absolute;
  top: -12%;
  right: 15%;
  height: 350px;
  z-index: 2;
  transition: 0.5s ease;
  transform: translateX(${props => (props.visible ? 0 : '-100px')});
  opacity: ${props => (props.visible ? 1 : 0)};

  &:before {
    content: '';
    background-color: var(--primary);
    height: 140px;
    width: 160px;
    position: absolute;
    top: 35%;
    left: 8%;
    filter: blur(120px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 80px;
      width: 140px;
      bottom: 0;
    }

    @media (max-width: 480px) {
      height: 40px;
      width: 140px;
      bottom: 0;
    }
  }

  > img {
    box-shadow: var(--shadow-banners);
  }

  @media (max-width: 1200px) {
    right: 0;
  }

  @media (max-width: 955px) {
    height: 280px;
  }

  @media (max-width: 900px) {
    transition: 1s ease;
  }

  @media (max-width: 700px) {
    height: 240px;
  }

  @media (max-width: 600px) {
    height: 200px;
    top: -4%;
  }

  @media (max-width: 400px) {
    height: 160px;
  }
`

export const SecondOverlap = styled.div<BannerProps>`
  position: absolute;
  bottom: 28%;
  right: -3%;
  z-index: 3;
  transform: translateX(${props => (props.visible ? 0 : '100px')});
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 0.5s ease;
  transition-delay: 0.2s;

  &:before {
    content: '';
    background-color: var(--primary);
    height: 35px;
    width: 200px;
    position: absolute;
    top: 5%;
    left: -5%;
    filter: blur(80px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 20px;
      width: 140px;
      bottom: 0;
      left: 20px;
    }
  }

  > img {
    box-shadow: var(--shadow-banners);
  }

  @media (max-width: 1349px) {
    right: 2%;
  }

  @media (max-width: 1200px) {
    right: 0;

    > img {
      height: 60px;
    }
  }

  @media (max-width: 955px) {
    bottom: 36%;

    > img {
      height: 50px;
    }
  }

  @media (max-width: 900px) {
    transition: 1s ease;
  }

  @media (max-width: 855px) {
    bottom: 28%;
  }

  @media (max-width: 755px) {
    bottom: 24%;
  }

  @media (max-width: 700px) {
    bottom: 54%;
    right: -2%;

    > img {
      height: 40px;
    }
  }

  @media (max-width: 600px) {
    bottom: 54%;

    > img {
      height: 34px;
    }
  }

  @media (max-width: 485px) {
    bottom: 50%;
  }

  @media (max-width: 460px) {
    bottom: 44%;
  }

  @media (max-width: 440px) {
    bottom: 42%;
  }

  @media (max-width: 400px) {
    bottom: 50%;

    > img {
      height: 28px;
    }
  }
`

export const ButtonOverlap = styled.div<BannerProps>`
  position: absolute;
  bottom: -5%;
  right: 10%;
  z-index: 3;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: 0.5s ease;
  transition-delay: 0.1s;

  &:before {
    content: '';
    background-color: var(--primary);
    height: 20px;
    width: 200px;
    position: absolute;
    top: 30%;
    left: -5%;
    filter: blur(30px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 10px;
      width: 140px;
      left: 20px;
    }
  }

  > img {
    box-shadow: var(--shadow-banners);
  }

  @media (max-width: 1250px) {
    > img {
      height: 50px;
    }
  }

  @media (max-width: 1135px) {
    right: 5%;
    > img {
      height: 40px;
    }
  }

  @media (max-width: 900px) {
    transition: 1s ease;
  }

  @media (max-width: 800px) {
    right: 0;
    > img {
      height: 35px;
    }
  }

  @media (max-width: 700px) {
    > img {
      height: 45px;
    }
  }

  @media (max-width: 480px) {
    > img {
      height: 40px;
    }
  }

  @media (max-width: 480px) {
    > img {
      height: 35px;
    }
  }
`
