import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    &::-webkit-scrollbar {
    display: none;
  }

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      /* background: var(--light-1); */
      background: transparent;
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--naval);
      border-radius: 8px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--background);
    }
  }

  html, border-style, #root{
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }

  #root {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;

    > div {
      width: 100%;
    }
  }

  body {
    overflow-x: hidden;
    background-color: var(--background);
    
    &::-webkit-scrollbar {
    display: none;
  }
  }
  
  *, button, input{
    border: 0;
    background: none;
    font-family: 'Lexend Deca', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  :root{
    --primary: #36A9E1;
    --primary-opacity: #36A9E140;
    --primary-hover: #cce9f7;
    --primary-header: #3face2;
    --primary-light: #cce9f7;
    --seccondary: #002D59;
    --background: #F2F2F2;
    --naval: #FFFFFF;
    --white: #1A1A1A;
    --black-opacity: #c5c5c5;
    --inputs: #E6E6E6;
    --text: #8C8F8D;
    --select: #f9f9f9;
    --deselect: #3C4042;
    --placeholder: #848484;
    --error: #ECAA00;
    --red-alert: #FF5757;
    --red-alert-hover: #b74141;
    --shadow: 0px 5px 15px 0px rgba(0,0,0,0.15);
    --shadow-light: 0px 5px 15px 0px rgba(0,0,0,0.05);
    --shadow-banners: 0px 5px 15px 0px rgba(60,61,63,0.1);

    --border: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(248, 188, 194, 0.4) 25%, rgba(245, 214, 217, 0.25) 50%, rgba(255, 255, 255, 0.5) 75%, rgba(199, 137, 143, 0.4) 100%)
      1;
      --gradient:  rgba(255, 255, 255, 0.5) 75%, rgba(199, 137, 143, 0.4) 100%;
  }
  
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);

  }
`
// screenX
// :
// 268
// screenY
// :
// 621
