export const questions = [
  {
    label: 'Como lançar um produto na Keed?',
    answers:
      'Lançar um produto conosco é super fácil! Seja ele físico ou digital, basta criar sua conta na Keed, preencher as configurações do produto de acordo com suas estratégias de venda e pronto! Depois, é só convidar seus afiliados para promoverem seu produto!'
  },
  {
    label: 'O que são Nuis?',
    answers:
      'Os Nuis são o sistema de ranqueamento de produtos dentro da Keed. Quanto mais engajamento, afiliados e vendas um produto tiver, maior será seu número de Nuis e consequentemente melhor será seu posicionamento na plataforma.'
  },
  {
    label: 'Como saber quanto vou receber de comissão?',
    answers:
      'Antes de se afiliar a um produto, confira os dados de afiliação dele. Na página do produto todas as informações destinadas aos afiliados são exibidas, como a taxa de comissão, formato da comissão e os termos e diretrizes de afiliação. Agora, se você quer saber em relação a todas as suas vendas, temos uma seção chamada "Relatórios", onde você pode visualizar seu desempenho na plataforma!'
  },
  {
    label: 'Preciso pagar algum valor para lançar um produto?',
    answers:
      'Não. Os produtos são cadastrados e exibidos na plataforma de forma gratuita! A única forma de pagamento à plataforma acontece em forma de uma taxa bem pequena sobre vendas realizadas conosco, para que a Keed possa continuar em pleno funcionamento!'
  },
  {
    label: 'O que são os termos de afiliação?',
    answers:
      'São termos que definem regras e métodos de venda para os afiliados de um produto específico. Esses termos são definidos pelo produtor e exibidos na página do produto na Keed. Sempre verifique os dados apresentados pelo produtor para evitar quaisquer conflitos posteriores!'
  }
]

export const questionsFAQPage = [
  {
    id: 0,
    label: 'Como lançar um produto na Keed?',
    answers:
      'Lançar um produto na Keed é rápido e descomplicado! Ao realizar seu cadastro, você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  },
  {
    id: 1,
    label: 'O que são Nuis?',
    answers:
      'Keed é rápido e descomplicado! Ao realizar seu cadastro, você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  },
  {
    id: 2,
    label: 'Como excluir afiliados do meu curso?',
    answers:
      'Um produto na Keed é rápido e descomplicado! Ao realizar seu cadastro, você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  },
  {
    id: 3,
    label: 'Preciso pagar algum valor para lançar um produto?',
    answers:
      'Lançar um produto na Keed é  e descomplicado! Ao realizar seu cadastro, você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  },
  {
    id: 4,
    label: 'O que são os termos de afiliação?',
    answers:
      'Lançar um produto na  é rápido e descomplicado! Ao realizar seu cadastro, você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  },
  {
    id: 5,
    label: 'Como excluir o meu curso?',
    answers:
      'Lançar um produto na Keed é rápido e ! Ao realizar seu cadastro, você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  },
  {
    id: 6,
    label: 'Preciso pagar algum valor para lançar um curso?',
    answers:
      'Lançar  produto na Keed é rápido e descomplicado! Ao realizar seu cadastro, você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  },
  {
    id: 7,
    label: 'O que são os termos de afiliação de um produto?',
    answers:
      'Lançar um produto na Keed é rápido e descomplicado! Ao realizar seu , você já pode começar. Nós seguimos a criação do seu produto desde os dados gerais até a configuração das suas estratégias de venda e personalização do checkout para o cliente final. Assim que seu produto é criado, ele já é exibido em nosso banco para todos os nossos afiliados, e você começará a receber solicitações de afiliação. Com a Keed, você configura todos os dados de seus afiliados, define comissões e gerentes que te ajudarão a manter sua casa em ordem. Depois de todos esses passos, é só começar a receber pagamentos!'
  }
]
