import styled from 'styled-components'

export const Container = styled.div`
  background: var(--naval);
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--inputs);

  > div:last-child > section {
    display: none;
  }
`

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Questions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`

interface RowProps {
  opened?: boolean
}

export const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 24px 26px;
  transition: 0.3s ease;
  scroll-margin-top: 4rem;

  > p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--white);
    position: relative;

    @media (max-width: 600px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }

    > span {
      position: absolute;
      top: -200px;
    }
  }

  > svg {
    transition: 0.3s ease;
    transform: rotate(${props => (props.opened ? '-90deg' : 'inherit')});

    @media (max-width: 600px) {
      width: 12px;
    }
  }

  @media (max-width: 600px) {
    gap: 16px;
    padding: 16px;
  }
`

export const Divisor = styled.section`
  display: flex;
  width: 100%;
  height: 1px;
  background-color: var(--inputs);
  border-radius: 1px;
`

export const Answers = styled.div<RowProps>`
  padding: ${props => (!props.opened ? '0 26px' : '24px 26px')};
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background-color: #f9f9f9;
  width: 100%;
  transition: 0.3s ease;
  visibility: ${props => (!props.opened ? 'hidden' : 'visible')};
  opacity: ${props => (!props.opened ? 0 : 1)};
  height: ${props => (!props.opened ? 0 : 'inherit')};

  > p {
    max-width: 853px;
  }
`
