import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background: var(--naval);
  overflow-x: auto;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 32px 0 0 !important;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 980px;
  padding: 32px;

  @media (max-width: 1024px) {
    padding: 0 !important;
  }
`

interface BannerProps {
  bg?: string
}

export const Banner = styled.div<BannerProps>`
  display: flex;
  width: 100%;
  height: 410px;
  background-image: ${props => `url("${props.bg}")`};
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 70px;
  justify-content: center;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 980px;
    padding: 0 32px;

    > img {
      object-fit: contain;

      @media (max-width: 681px) {
        width: 280px;
        margin-bottom: 0;
      }
    }

    > div {
      display: flex;
      align-items: center;

      > h4 {
        font-weight: 800;
        font-size: 42px;
        color: var(--background);
        max-width: 566px;

        > b {
          font-weight: 900;
          color: var(--white);
        }

        @media (max-width: 947px) {
          font-size: 36px;
        }
        @media (max-width: 681px) {
          font-size: 30px;
        }
        @media (max-width: 312px) {
          font-size: 24px;
        }
      }
      @media (max-width: 681px) {
        justify-content: center;
        text-align: center;
      }
    }

    @media (max-width: 681px) {
      flex-direction: column;
      padding: 32px 24px 0 24px;
      align-items: center;
      gap: 16px;
    }

    @media (max-width: 312px) {
      padding: 32px 12px 0 12px;
    }
  }

  @media (max-width: 681px) {
    height: 100%;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 0 32px 32px 32px;
  }
  @media (max-width: 680px) {
    padding: 0 24px 32px 24px;
  }
`

export const Title = styled.h1`
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: var(--white);
  max-width: 611px;
`

export const DivisorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  align-items: flex-start;

  > div {
    height: 2px;
    max-width: 269px;
    width: 100%;
    background: var(--primary);
    border-radius: 2px;
  }
`

export const StaticBanner = styled.div`
  display: flex;
  padding: 40px;
  background-color: var(--primary);
  width: 100%;
  justify-content: center;

  > div {
    display: flex;
    max-width: 980px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    > div {
      max-width: 550px;
      width: 100%;
      display: flex;
      flex-direction: column;
      > h1 {
        font-weight: 700;
        font-size: 38px;
        line-height: 38.34px;
        color: var(--background);

        > b {
          color: var(--white);
          font-weight: 900;
        }

        @media (max-width: 1024px) {
          font-size: 32px;
          line-height: 32.29px;
        }
        @media (max-width: 680px) {
          font-size: 26px;
          line-height: 26.23px;
        }
      }
      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }

    > button {
      box-shadow: var(--shadow);
      max-width: 267px;
      width: 100%;
      color: var(--white);
      background: var(--naval);
      font-weight: 900;
      font-size: 22px;
      display: flex;
      justify-content: center;
      padding: 16px 14px;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: var(--primary);
        transition: 0.3s ease;
      }

      @media (max-width: 680px) {
        padding: 8px 14px;
        font-size: 16px;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 24px;
      padding: 0;
    }
  }
`

export const Button = styled.div`
  display: flex;
  max-width: 394px;
  width: 100%;
  border-radius: 10px;
  padding: 14px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: white;
  background-color: var(--primary);
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: var(--seccondary);
    color: white;
    transition: 0.3s ease;
  }

  @media (max-width: 1024px) {
    text-align: center;
  }

  @media (max-width: 680px) {
    font-size: 14px;
    padding: 8px;
    max-width: 278px;
  }
`

export const ColumnCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 96px;
  align-items: center;

  > div:nth-child(2) {
    > div {
      text-align: start;

      @media (max-width: 1024px) {
        text-align: end;
        align-items: flex-end;
      }
    }
    > div:last-child > div:first-child > div {
      @media (max-width: 1024px) {
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
    }
  }

  > div:nth-child(1) {
    > div {
      text-align: end;

      @media (max-width: 1024px) {
        text-align: start;
        align-items: flex-start;
      }
    }
  }

  > div:nth-child(3) {
    > div {
      text-align: end;

      @media (max-width: 1024px) {
        text-align: start;
        align-items: flex-start;
      }
    }
  }

  > div:nth-child(3) > div > section {
    max-width: 451px;
  }

  @media (max-width: 1024px) {
    gap: 64px;
    padding: 0 32px 32px 32px;
  }

  @media (max-width: 680px) {
    gap: 32px;
    padding: 0 24px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 64px;
  width: 100%;
  align-items: center;

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
    background: var(--inputs);
    border-radius: 10px;
    max-width: 687px;
  }
`

export const TextContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  color: var(--white);

  @media (max-width: 1024px) {
    padding: 10px 24px 24px 24px;
  }

  @media (max-width: 680px) {
    gap: 8px;
  }
`

export const LittleTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  > span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 1024px) {
      font-size: 20px;
    }

    @media (max-width: 680px) {
      font-size: 14px;
    }
  }

  > div {
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: var(--primary);

    @media (max-width: 1024px) {
      background: linear-gradient(
        90deg,
        #f2b705 -0.49%,
        rgba(242, 183, 5, 0) 63.61%
      );
    }
  }
`

interface TitleProps {
  lastCard?: number
}

export const TitleCards = styled.section<TitleProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 49px;
  max-width: ${props => (props.lastCard === 2 ? '100% !important' : '')};

  > b {
    font-weight: 800;
    white-space: nowrap;
  }

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 29.26px;
    max-width: 457px;
  }

  @media (max-width: 680px) {
    font-size: 16px;
    line-height: 19.5px;
  }
`

interface TextProps {
  leftCards?: number
}

export const Text = styled.div<TextProps>`
  font-weight: 400;
  font-size: 24px;
  display: flex;
  justify-content: ${props =>
    props.leftCards === 1 ? 'flex-start' : 'flex-end'};
  line-height: 29px;

  > p {
    max-width: ${props => (props.leftCards === 1 ? '100%' : '480px')};

    @media (max-width: 1024px) {
      max-width: 457px;
    }
  }

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 15px;
  }
`

export const ImageContent = styled.div`
  width: fit-content;
  height: 100%;

  > img:first-child {
    box-shadow: var(--shadow);
    border-radius: 10px;
    height: 100%;
    width: 417px;
    object-fit: cover;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  > img:last-child {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const PrimaryTitle = styled.span`
  color: var(--primary);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: -28px;
`

export const Card = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  background-color: var(--naval);
  justify-content: space-between;
  gap: 24px;
  box-shadow: var(--shadow);

  > div:first-child {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0 24px 24px;
    gap: 16px;
    color: var(--white);

    > h1 {
      font-weight: 800;
      font-size: 30px;
      line-height: 37px;
      color: var(--primary);

      @media (max-width: 1024px) {
        font-size: 28px;
        line-height: 34px;
        max-width: 514px;
      }

      @media (max-width: 680px) {
        font-size: 20px;
      }
    }

    > span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;

      @media (max-width: 1024px) {
        padding-top: 8px;
        margin-bottom: -8px;
      }
      @media (max-width: 680px) {
        font-size: 14px;
        padding-top: 0;
      }
    }

    > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19.5px;

      @media (max-width: 1024px) {
        margin-bottom: -4px;
      }

      @media (max-width: 680px) {
        font-size: 14px;
      }
    }

    @media (max-width: 1024px) {
      padding: 0 24px 24px 24px;
      text-align: center;
      max-width: 601px;
      align-items: center;
    }

    @media (max-width: 680px) {
      padding: 0 24px 24px 24px;
      text-align: center;
      max-width: 601px;
      align-items: center;
    }
  }

  > div:last-child {
    height: 100%;
    width: 417px;

    > img {
      width: 417px;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;

      @media (max-width: 1024px) {
        width: 100%;
        object-position: center 10%;
      }
      @media (max-width: 680px) {
        object-position: top;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
      padding: 16px 24px 0 24px;
      height: 190px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: center;
    max-width: 686px;
  }
`

export const ColumnFAQ = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 32px 24px !important;
  }

  @media (max-width: 680px) {
    margin-top: -32px;
  }
`
