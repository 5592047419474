import styled from 'styled-components'

interface BannerProps {
  visible?: boolean
}

export const BannerContainerAffiliates = styled.nav<BannerProps>`
  max-width: 100%;
  max-height: 450px;
  margin: 8px 0;
  position: relative;

  > img:first-child {
    filter: blur(${props => (props.visible ? '4px' : 0)});
    transition: 0.3s ease;
    border-radius: 10px;
    width: 90%;
    box-shadow: var(--shadow-banners);

    @media (max-width: 900px) {
      display: none;
    }
  }

  .responsive-producer {
    filter: blur(${props => (props.visible ? '4px' : 0)});
    transition: 1s ease;
    border-radius: 10px;
    width: 100%;
    box-shadow: var(--shadow-banners);

    @media (min-width: 901px) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    max-height: 100%;
  }
`

export const FirstOverlap = styled.div<BannerProps>`
  display: flex;
  position: absolute;
  top: -20%;
  right: -15%;
  height: 350px;
  z-index: 1;
  transition: 0.5s ease;
  transform: translateX(${props => (props.visible ? 0 : '100px')});
  opacity: ${props => (props.visible ? 1 : 0)};

  &:before {
    content: '';
    background-color: var(--primary);
    height: 140px;
    width: 160px;
    position: absolute;
    top: 35%;
    left: 8%;
    filter: blur(120px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 50px;
      width: 120px;
      inset: 0;
    }

    @media (max-width: 480px) {
      height: 30px;
      width: 120px;
      top: 50%;
    }
  }

  > img {
    border-radius: 5px;
    height: 270px;
    box-shadow: var(--shadow-banners);

    @media (max-width: 1148px) {
      height: 170px;
    }

    @media (max-width: 865px) {
      height: 130px;
    }
  }

  @media (max-width: 1672px) {
    right: 0;
  }

  @media (max-width: 900px) {
    top: -5%;
    right: -2%;
    transition: 1s ease;
    > img {
      height: 240px;
    }
  }

  @media (max-width: 800px) {
    > img {
      height: 200px;
    }
  }

  @media (max-width: 750px) {
    > img {
      height: 180px;
    }
  }

  @media (max-width: 700px) {
    > img {
      height: 160px;
      object-fit: cover;
    }
  }

  @media (max-width: 600px) {
    > img {
      height: 140px;
    }
  }

  @media (max-width: 550px) {
    > img {
      height: 120px;
    }
  }

  @media (max-width: 490px) {
    > img {
      height: 90px;
    }
  }

  @media (max-width: 390px) {
    > img {
      height: 80px;
    }
  }
`

export const SecondOverlap = styled.div`
  position: absolute;
  bottom: 0;
  right: -25%;
  z-index: 2;

  &:before {
    content: '';
    background-color: var(--primary);
    height: 35px;
    width: 200px;
    position: absolute;
    top: 5%;
    left: -5%;
    filter: blur(80px);
    z-index: -1;
  }

  > img {
    border-radius: 5px;
    height: 300px;
    box-shadow: var(--shadow-banners);
  }
`

export const ThirdOverlap = styled.div<BannerProps>`
  position: absolute;
  bottom: -45%;
  right: 64px;
  z-index: 2;
  transition: 0.5s ease;
  transition-delay: 0.1s ease;
  transform: translateY(${props => (props.visible ? 0 : '50px')});
  opacity: ${props => (props.visible ? 1 : 0)};

  &:before {
    content: '';
    background-color: var(--primary);
    height: 20px;
    width: 200px;
    position: absolute;
    top: 30%;
    left: -5%;
    filter: blur(30px);
    z-index: -1;

    @media (max-width: 900px) {
      height: 20px;
      width: 60px;
      inset: 0;
    }
  }

  > img {
    border-radius: 5px;
    height: 250px;
    box-shadow: var(--shadow-banners);

    @media (max-width: 1148px) {
      height: 200px;
    }
  }

  @media (max-width: 1148px) {
    bottom: -40%;
  }

  @media (max-width: 900px) {
    bottom: -10%;
    transition: 1s ease;
  }

  @media (max-width: 750px) {
    > img {
      height: 180px;
    }
  }

  @media (max-width: 700px) {
    > img {
      height: 160px;
      object-fit: cover;
    }
  }

  @media (max-width: 600px) {
    > img {
      height: 140px;
    }
  }

  @media (max-width: 550px) {
    > img {
      height: 120px;
    }
  }

  @media (max-width: 490px) {
    > img {
      height: 90px;
    }
  }

  @media (max-width: 390px) {
    > img {
      height: 80px;
    }
  }
`
