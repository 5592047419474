import React, { useState } from 'react'
import Footer from '../Components/Footer'
import * as S from './styles'
import { PrimaryTitle } from '../AboutUs/styles'
import { MdSearch } from 'react-icons/md'
// import { questionsFAQPage } from '../Components/FAQ/statics'
import FaqQuestions from './faqQuestions'
import { sectorsMap } from './statics'
import { ReactComponent as Arrow } from '../../../assets/icons/Faq/arrowRight.svg'
import { listAllCategories, listQuestions } from '../../../lib/apiFAQ'
import { useGeneral } from '../../../contexts/GeneralContext'
import { FaElementor } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Oval } from '@agney/react-loading'
import Header from '../../../components/Header'

export interface IFAQSection {
  id: string
  is_active: boolean
  label: string
  topics: number
}

const FAQPage: React.FC = () => {
  const [valueInput, setValueInput] = useState('')
  // const [FAQ, setFAQ] = useState(questionsFAQPage)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [allFAQs, setAllFAQs] = useState<
    { question: string; id: string; category: string }[]
  >([])
  const [searchFAQs, setSearchFAQs] = useState<
    { question: string; id: string; category: string }[]
  >([])
  const { setIsLoading } = useGeneral()

  const handleSearch = (e: string) => {
    setLoadingSearch(true)
    setValueInput(e)
    const result = allFAQs.filter(
      (question: { category: string; id: string; question: string }) =>
        question?.question.toLowerCase().includes(e.toLowerCase())
    )
    setSearchFAQs(result)

    setLoadingSearch(false)
  }

  const [opened, setOpened] = useState<number | string | null>(null)

  // versão anterior estática de pesquisa
  // deixei comentado caso precise de alguma revisão após integração
  // const uniqueFAQ = () => {
  //   const seen: { [x: string]: boolean } = {}
  //   return questionsFAQPage
  //     .filter(value => {
  //       return (
  //         new RegExp(valueInput.replace(/\?/g, '\\?'), 'i').exec(
  //           value.label
  //         ) !== null
  //       )
  //     })
  //     .filter(function (item) {
  //       return Object.prototype.hasOwnProperty.call(seen, item.label)
  //         ? false
  //         : (seen[item.label] = true)
  //     })
  // }

  const [section, setSection] = useState<IFAQSection[]>([])
  const navigate = useNavigate()

  const OnLoad = async () => {
    setIsLoading(true)
    const allQuestions = await listQuestions()
    if (allQuestions?.data?.body) {
      setAllFAQs(allQuestions?.data?.body)
    }
    const response = await listAllCategories()
    if (response?.data?.body) {
      setSection(response?.data?.body)
    }

    setIsLoading(false)
  }
  React.useEffect(() => {
    OnLoad()
  }, [])

  return (
    <S.Container>
      <Header />
      <S.Content style={{ height: '100%' }}>
        <S.Background>
          <h1>Qual sua dúvida?</h1>
          <S.SearchContainer>
            <div>
              <input
                placeholder="Informe aqui sua dúvida..."
                type="text"
                value={valueInput}
                autoComplete="false"
                onChange={e => handleSearch(e.target.value)}
              />
              <MdSearch size={18} color="var(--primary)" />
            </div>
            <S.SearchResult active={valueInput}>
              {loadingSearch ? (
                <S.LoadWrapper>
                  <Oval />
                </S.LoadWrapper>
              ) : (
                <>
                  {' '}
                  {searchFAQs &&
                    Array.isArray(searchFAQs) &&
                    searchFAQs.length > 0 &&
                    searchFAQs.map((item, i) => (
                      <FaqQuestions
                        label={item.question}
                        key={i}
                        valueInput={valueInput}
                        href={`/faq-answer/${item.category}/${item.id}`}
                        onClick={() => {
                          item?.id === opened
                            ? setOpened(null)
                            : setOpened(item.id)
                        }}
                      />
                    ))}
                  {searchFAQs.length === 0 && (
                    <S.QuestionsSearch>
                      Não encontramos sua dúvida
                    </S.QuestionsSearch>
                  )}
                </>
              )}
            </S.SearchResult>
          </S.SearchContainer>
        </S.Background>
      </S.Content>
      <S.Content style={{ padding: 0, background: 'white' }}>
        <S.Wrapper
          style={{ gap: 32, alignItems: 'center', padding: '64px 24px' }}
        >
          <S.Column>
            <PrimaryTitle style={{ margin: 0 }}>CENTRAL DE AJUDA</PrimaryTitle>
            <S.Title>Confira os setores de ajuda</S.Title>
          </S.Column>
          {/* <FAQContent opened={opened} setOpened={setOpened} /> */}
          <S.SectorsContainer>
            {section.map((item, i) => {
              return item.is_active && item.topics > 0 ? (
                <S.Card
                  key={i}
                  onClick={() => navigate(`/faq-modules/${item.id}`)}
                >
                  <S.ContentCard>
                    <S.IconContent>
                      {' '}
                      {sectorsMap.find(ele => ele.id === item.id)?.icon || (
                        <FaElementor />
                      )}
                    </S.IconContent>
                    <S.Text>{item.label}</S.Text>
                  </S.ContentCard>
                  <Arrow />
                </S.Card>
              ) : (
                <></>
              )
            })}
          </S.SectorsContainer>
        </S.Wrapper>
      </S.Content>
      <Footer />
    </S.Container>
  )
}

export default FAQPage
