import React from 'react'
import { ReactComponent as Arrow } from '../../../../../assets/icons/Faq/arrowLeftt.svg'
import * as S from './styles'
import Footer from '../../../Components/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { sectorsMap } from '../../statics'
import { listUniqueCategories } from '../../../../../lib/apiFAQ'
import { useGeneral } from '../../../../../contexts/GeneralContext'
import { dataToHTML } from './utils'
import Header from '../../../../../components/Header'

const FaqAnswer: React.FC = () => {
  const { alertError, setIsLoading } = useGeneral()
  const navigate = useNavigate()
  const { section_id, question_id } = useParams()
  const [data, setData] = React.useState<any[]>([])
  const [title, setTitle] = React.useState('')
  const onLoad = async () => {
    setIsLoading(true)
    if (
      question_id &&
      section_id &&
      sectorsMap.find(ele => ele.id === section_id)?.label
    ) {
      try {
        const response = await listUniqueCategories(section_id)
        if (response?.data?.body) {
          const findBuilderComponents = response?.data?.body.find(
            (ele: any) => ele.id === question_id
          )
          if (
            findBuilderComponents?.question &&
            findBuilderComponents?.question.length > 0
          ) {
            setTitle(findBuilderComponents.question)
          }
          if (findBuilderComponents && findBuilderComponents?.answer) {
            setData(dataToHTML(JSON.parse(findBuilderComponents.json_data)))
          } else {
            setIsLoading(false)
            navigate('/faq')
          }
        }
      } catch (err: any) {
        alertError(err)
        setIsLoading(false)
        navigate('/faq')
      }
    } else {
      setIsLoading(false)
      navigate('/faq')
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    onLoad()
  }, [])

  return (
    <>
      <S.Container>
        <Header dark />

        <S.GeneralContainer>
          <S.BackButton onClick={() => navigate(-1)}>
            <Arrow /> CENTRAL DE AJUDA
          </S.BackButton>
          <S.Content>
            <h1>{title}</h1>
            {data}
          </S.Content>
        </S.GeneralContainer>
        <Footer />
      </S.Container>
    </>
  )
}

export default FaqAnswer
