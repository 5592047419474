import React from 'react'
import { pages } from './statics'

import * as S from './styles'

interface ResponsiveHeaderProps {
  opened?: boolean
  setOpened?: any
}

const ResponsiveHeader: React.FC<ResponsiveHeaderProps> = ({
  opened,
  setOpened
}) => {
  return (
    <S.ContainerResponsive opened={opened}>
      <S.Content style={{ flexDirection: 'column' }} opened={opened}>
        {pages.map((item, i) => (
          <a
            key={i}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
              setOpened(false)
            }}
            href={item.url}
          >
            {item.label}
          </a>
        ))}
      </S.Content>
      <S.ButtonsContainer opened={opened}>
        <S.Button href={process.env.REACT_APP_WIKI_LOGIN} target="_blank">
          Login
        </S.Button>
        <S.Button href={process.env.REACT_APP_WIKI_REGISTER} target="_blank">
          Cadastre-se
        </S.Button>
      </S.ButtonsContainer>
    </S.ContainerResponsive>
  )
}

export default ResponsiveHeader
