import React, { useRef } from 'react'
import ProducerBanner from '../../../../assets/images/LandingPageV2/producerBanner/main-banner-producer.png'
import ResponsiveProducerBanner from '../../../../assets/images/LandingPageV2/producerBanner/responsive-main-banner-producer.png'
import FirstOverlapProducer from '../../../../assets/images/LandingPageV2/producerBanner/first-overlap-producer.png'
// import SecondOverlapProducer from '../../../../assets/images/LandingPageV2/producerBanner/second-overlap-producer.png'
import ThirdOverlapProducer from '../../../../assets/images/LandingPageV2/producerBanner/third-overlap-producer.png'
import * as S from './styles'
import { getElementVisibility } from '../..'

interface ProducerProps {
  scrollPosition: number
}

const Producer: React.FC<ProducerProps> = ({ scrollPosition }) => {
  const imagesRef: {
    mainBanner: React.MutableRefObject<HTMLDivElement | null>
    firstOverlap: React.MutableRefObject<HTMLDivElement | null>
    thirdOverlap: React.MutableRefObject<HTMLDivElement | null>
  } = {
    mainBanner: useRef(null),
    firstOverlap: useRef(null),
    thirdOverlap: useRef(null)
  }

  return (
    <S.BannerContainerAffiliates
      ref={imagesRef.mainBanner}
      visible={getElementVisibility(
        imagesRef.thirdOverlap.current,
        scrollPosition
      )}
    >
      <img src={ProducerBanner} />
      <img src={ResponsiveProducerBanner} className="responsive-producer" />
      <S.FirstOverlap
        ref={imagesRef.firstOverlap}
        visible={getElementVisibility(
          imagesRef.firstOverlap.current,
          scrollPosition
        )}
      >
        <img src={FirstOverlapProducer} />
      </S.FirstOverlap>
      {/* <S.SecondOverlap>
        <img src={SecondOverlapProducer} />
      </S.SecondOverlap> */}
      <S.ThirdOverlap
        ref={imagesRef.thirdOverlap}
        visible={getElementVisibility(
          imagesRef.thirdOverlap.current,
          scrollPosition
        )}
      >
        <img src={ThirdOverlapProducer} />
      </S.ThirdOverlap>
    </S.BannerContainerAffiliates>
  )
}

export default Producer
