// /notifications/management/faq-categories/:category/toggle-is-active
import api from './api'
// import { ElementsFAQ } from '../contexts/AdminFAQContext'

export interface FAQData {
  category: string
  question: string
  answer: string
  json_data: string
}

export const listAllCategories = async (): Promise<any> => {
  const response = await api.get('/email/faqs/categories')
  return response
}

export const listUniqueCategories = async (category: string): Promise<any> => {
  const response = await api.get(`/email/faqs?category=${category}`)
  return response
}

export const updateFAQ = async (id: string, data: FAQData): Promise<any> => {
  const response = await api.patch(`/notifications/management/faqs/${id}`, data)
  return response
}

export const deleteFAQ = async (id: string, category: string): Promise<any> => {
  const response = await api.delete(
    `/notifications/management/faqs/${category}/${id}`
  )
  return response
}

export const searchQuestion = async (category: string): Promise<any> => {
  const response = await api.get(`/email/faqs?search=${category}`)
  return response
}

export const listQuestions = async (): Promise<any> => {
  const response = await api.get('/email/faqs/questions')
  return response
}
