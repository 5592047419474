import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background: var(--background);
  overflow-x: auto;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 980px;
  padding: 32px;

  @media (max-width: 800px) {
    max-width: 676px;
  }
`

export const Background = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 1);
  background: linear-gradient(
    180deg,
    rgba(26, 26, 26, 1) 8%,
    rgba(0, 45, 89, 1) 22%,
    rgba(24, 101, 150, 1) 47%,
    rgba(54, 169, 225, 1) 64%,
    rgba(255, 255, 255, 1) 96%
  );
  align-items: center;
  color: white;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  height: 100vh;

  > h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;

    @media (max-width: 800px) {
      font-weight: 800;
      font-size: 36px;
      line-height: 44px;
    }

    @media (max-width: 600px) {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0 24px;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  align-items: center;
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: var(--white);
  max-width: 611px;

  @media (max-width: 600px) {
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    max-width: 278px;
  }
`

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;

  > div:first-child {
    display: flex;
    max-width: 822px;
    width: 100%;
    justify-content: center;
    position: relative;
    align-items: center;

    > svg {
      position: absolute;
      right: 16px;
    }

    > input {
      width: 100%;
      max-width: 822px;
      border-radius: 10px;
      box-shadow: var(--shadow);
      color: var(--deselect);
      background: white;
      padding: 14px 24px;

      :focus,
      :active {
        outline: none;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--deselect);
        opacity: 1; /* Firefox */
        font-style: italic;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
      }

      @media (max-width: 1024px) {
        max-width: 624px;
      }
    }
  }
`

interface SearchProps {
  active?: any
}

export const SearchResult = styled.div<SearchProps>`
  display: flex;
  flex-direction: column;
  max-width: 822px;
  width: 100%;
  position: absolute;
  border-radius: 10px;
  background: white;
  padding: 12px;
  height: fit-content;
  top: 50px;
  bottom: 0;
  overflow-x: auto;
  max-height: 341px;
  opacity: ${props => (props.active !== '' ? '1' : '0')};
  pointer-events: ${props => (props.active !== '' ? 'visible' : 'none')};

  ::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
    border: 8px solid var(--white);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--seccondary);
  }
`

interface QuestionsProps {
  isSearching?: string
}

export const QuestionsSearch = styled.a<QuestionsProps>`
  padding: 12px 12px;
  border-radius: 10px;
  cursor: pointer;
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  text-align: start;
  text-decoration: none;

  > p {
    color: var(--primary);
    font-weight: 700;
    display: inline;
  }

  &:hover {
    transition: 0.3s ease;
    background: #272b2f1a;
  }
`

export const SectorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`

export const Card = styled.div`
  display: flex;
  width: 100%;
  max-width: calc((100% - 12px) / 2);
  border: 1px solid var(--inputs);
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  gap: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--inputs);
    transition: 0.3s ease;
  }

  @media (max-width: 769px) {
    max-width: 100%;
  }
`

export const ContentCard = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
`

export const IconContent = styled.div`
  background-color: var(--inputs);
  border-radius: 5px;
  padding: 14px;
  max-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;

  > svg > path {
    stroke: var(--placeholder);
  }

  @media (max-width: 600px) {
    padding: 8px;
  }
`

export const Text = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: var(--white);

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

export const LoadWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  max-height: 50px;
  padding: 10px 0;
  width: 100%;
  padding-left: 15px;
  > svg {
    border: black;
    stroke: black;
    height: 30px;
    width: 30px;
  }
`
