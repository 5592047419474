import React, { useEffect, useState } from 'react'
import * as S from './styles'
import { ReactComponent as Logo } from '../../assets/logo/logo-blue.svg'
import { pages } from './statics'
import { HiMenuAlt1 } from 'react-icons/hi'
import ResponsiveHeader from './responsive-header'
import { useNavigate } from 'react-router-dom'

interface HeaderProps {
  dark?: boolean
}

const Header: React.FC<HeaderProps> = ({ dark }) => {
  const [opened, setOpened] = useState(false)
  const navigate = useNavigate()

  const [currentSection, setCurrentSection] = useState('')

  const handleScroll = () => {
    const scrollPosition = window.scrollY

    pages.forEach(item => {
      const sectionElement = document.getElementById(item.url.substring(1))
      if (sectionElement instanceof HTMLElement) {
        const sectionTop = sectionElement.offsetTop
        const sectionBottom = sectionTop + sectionElement.clientHeight

        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setCurrentSection(item.url)
        }
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <S.Container dark={dark}>
      <div>
        <S.LogoContainer
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

            navigate('/')
          }}
        >
          <Logo />
        </S.LogoContainer>
        <S.ToggleButton onClick={() => setOpened(!opened)}>
          <HiMenuAlt1 size={24} />
        </S.ToggleButton>
        <S.Content>
          {pages.map((item, i) => (
            <div key={i}>
              <a
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                  setOpened(false)
                }}
                href={item.url}
              >
                {item.label}
              </a>
              <S.Divisor active={currentSection === item.url} />
            </div>
          ))}
        </S.Content>
        <S.ButtonsContainer>
          <S.Button href={process.env.REACT_APP_WIKI_LOGIN} target="_blank">
            Entrar
          </S.Button>
          <S.Button href={process.env.REACT_APP_WIKI_REGISTER} target="_blank">
            Cadastre-se
          </S.Button>
        </S.ButtonsContainer>
        <ResponsiveHeader opened={opened} setOpened={setOpened} />
      </div>
    </S.Container>
  )
}

export default Header
