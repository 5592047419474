import React from 'react'
import { ReactComponent as Arrow } from '../../../../assets/icons/Faq/generalArrow.svg'
import * as S from './styles'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from '../../Components/Footer'
import { useGeneral } from '../../../../contexts/GeneralContext'
import { sectorsMap } from '../statics'
import { listUniqueCategories } from '../../../../lib/apiFAQ'
import Header from '../../../../components/Header'

export interface IFaq {
  id: string
  category: string
  question: string
  answer: string
  json_data: string
  created_at: Date
}

const ModulesFaq: React.FC = () => {
  const { setIsLoading, alertError } = useGeneral()
  const { section_id } = useParams()
  const navigate = useNavigate()
  const [topics, setTopics] = React.useState<IFaq[]>([])

  const onLoad = async () => {
    setIsLoading(true)
    if (section_id && sectorsMap.find(ele => ele.id === section_id)?.label) {
      try {
        const response = await listUniqueCategories(section_id)

        if (response?.data?.body) {
          setTopics(response?.data?.body)
        }
      } catch (err: any) {
        alertError(err)
      }
    } else {
      navigate('/faq')
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    onLoad()
  }, [section_id])

  return (
    <>
      <S.Container>
        <Header dark />

        <S.GeneralContainer>
          <S.HeaderPage>
            <p>CENTRAL DE AJUDA</p>
            <span>
              {sectorsMap.find(ele => ele.id === section_id)?.label || 'Seção'}
            </span>
          </S.HeaderPage>
          <S.CardContainer>
            {topics.map((item, i) => (
              <S.Card
                key={i}
                onClick={() => navigate(`/faq-answer/${section_id}/${item.id}`)}
              >
                <div style={{ paddingRight: 8 }}>{item.question}</div>
                <Arrow />
              </S.Card>
            ))}
          </S.CardContainer>
        </S.GeneralContainer>

        <Footer />
      </S.Container>
    </>
  )
}

export default ModulesFaq
