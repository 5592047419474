export const pages = [
  {
    label: 'Home',
    url: '#home'
  },

  {
    label: 'Funcionalidades',
    url: '#features'
  },

  {
    label: 'Novidades',
    url: '#news'
  },

  {
    label: 'Faq',
    url: '#faq'
  }
]
