import styled from 'styled-components'

export const GeneralContainer = styled.div`
  padding-top: 32px;
  margin-top: 70px;
  width: 100%;
  max-width: 980px;
`

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background: var(--naval);
  overflow-x: auto;
  justify-content: space-between;
`

export const HeaderPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 4px;

  > p {
    font-size: 14px;
    color: var(--primary);
    font-weight: 500;

    @media (max-width: 500px) {
      text-align: center;
    }
  }

  > span {
    font-weight: 800;
    font-size: 36px;
    color: var(--white);

    @media (max-width: 500px) {
      text-align: center;
    }
  }
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 16px 20px 32px;
  border: 1px solid var(--inputs);
  border-radius: 10px;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;

  > div {
    font-size: 18px;
    font-weight: 600;
    color: var(--white);

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  &:hover {
    background-color: var(--inputs);
    transition: 0.3s ease;
  }

  @media (max-width: 500px) {
    padding: 20px 8px;
  }

  @media (max-width: 350px) {
    flex-direction: column;
    text-align: center;
    gap: 4px;
  }
`

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;
  width: 100%;
`
