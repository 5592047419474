import React from 'react'

import { Routes, Route } from 'react-router-dom'
// import LandingPage from './pages/LandingPages'
import FAQPage from './pages/LandingPages/FAQ'
// import Modules from './pages/LandingPages/Modules'
// import AboutUs from './pages/LandingPages/AboutUs'
import ModulesFaq from './pages/LandingPages/FAQ/Modules'
import FaqAnswer from './pages/LandingPages/FAQ/Modules/FaqAnswer'
import LandingPageV2 from './pages/LandingPageV2'

const DefaultRoutes: React.FC = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<LandingPage />} /> */}
      <Route path="/" element={<LandingPageV2 />} />
      {/* <Route path="/modules/producer" element={<Modules />} />
      <Route path="/modules/affiliated" element={<Modules />} />
      <Route path="/modules/students" element={<Modules />} />
      {/* <Route path="/about-us" element={<AboutUs />} /> */}
      <Route path="/faq-modules/:section_id" element={<ModulesFaq />} />
      <Route
        path="/faq-answer/:section_id/:question_id"
        element={<FaqAnswer />}
      />
      <Route path="/faq" element={<FAQPage />} />
    </Routes>
  )
}

export default DefaultRoutes
