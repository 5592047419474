import React, { useState } from 'react'
import { ReactComponent as Arrow } from '../../../../assets/icons/LandingPage/arrowDown.svg'
import { questions } from './statics'
import * as S from './styles'

const FAQ: React.FC = () => {
  const [opened, setOpened] = useState<number | null>(null)

  return (
    <S.Container>
      {questions.map((item, i) => (
        <S.QuestionContainer key={i}>
          <S.Questions>
            <S.Row
              opened={i === opened}
              onClick={() => {
                i === opened ? setOpened(null) : setOpened(i)
              }}
            >
              <p>{item.label} </p>
              <Arrow />
            </S.Row>

            <S.Answers opened={i === opened}>
              <p>{item.answers}</p>
            </S.Answers>
          </S.Questions>
          <S.Divisor />
        </S.QuestionContainer>
      ))}
    </S.Container>
  )
}

export default FAQ
