import React from 'react'
import { ReactComponent as Logo } from '../../../../assets/logo/logoFooter.svg'
// import { ReactComponent as Apple } from '../../../../assets/icons/LandingPage/apple.svg'
// import { ReactComponent as Android } from '../../../../assets/icons/LandingPage/android.svg'
import { links, links1, supportArray } from './statics'
import * as S from './styles'
import { useNavigate } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa'
import { MdOutlineEmail } from 'react-icons/md'

const Footer: React.FC = () => {
  const navigate = useNavigate()
  return (
    <S.Container>
      <S.Wrapper>
        <S.Row>
          <S.LogoContainer>
            <Logo />
            <div>
              <span>Keed Impulsionadora de Vendas On Line LTDA</span>
              <span>46.883.248/0001-49</span>
            </div>
          </S.LogoContainer>
          <S.RowContent>
            {links.map((link, i) => (
              <S.ColumnContent key={i} style={{ width: '40%' }}>
                <h4>{link.title}</h4>
                <S.Column style={{ gap: 8 }}>
                  <span
                    onClick={() => {
                      if (link.label1?.link) {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

                        navigate(link.label1?.link)
                      }

                      if (link.label1.label === 'Instagram') {
                        window.open(
                          process.env.REACT_APP_SOCIAL_MEDIA_INSTAGRAM,
                          '_blank'
                        )
                      }
                    }}
                  >
                    {link.label1?.label}
                  </span>
                  <span
                    onClick={() => {
                      if (link.label2?.link) {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

                        navigate(link.label2?.link)
                      }

                      if (link.label2.label === 'Facebook') {
                        window.open(
                          process.env.REACT_APP_SOCIAL_MEDIA_FACEBOOK,
                          '_blank'
                        )
                      }
                    }}
                  >
                    {link.label2?.label}
                  </span>
                  <span
                    onClick={() => {
                      if (link.label3?.link) {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

                        navigate(link.label3.link)
                      }
                    }}
                  >
                    {link.label3?.label}
                  </span>
                  {/* <span
                    onClick={() => {
                      if (link.label4?.link) {
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

                        navigate(link.label4?.link)
                      }

                      if (
                        link.label4.label === 'E-mail' &&
                        process.env.REACT_APP_SOCIAL_MEDIA_EMAIL
                      ) {
                        window.open(
                          `mailto:${process.env.REACT_APP_SOCIAL_MEDIA_EMAIL}`,
                          '_blank'
                        )
                      }
                    }}
                  >
                    {link.label4?.label}
                  </span> */}
                  <span>{link.label4?.label}</span>
                </S.Column>
              </S.ColumnContent>
            ))}
            {links1.map((link, i) => (
              <S.ColumnContent key={i} style={{ width: '40%' }}>
                <h4>{link.title}</h4>
                <S.Column style={{ gap: 8 }}>
                  <a href={link.label1?.link} target="_blank">
                    {link.label1?.label}
                  </a>
                  <a href={link.label2?.link} target="_blank">
                    {link.label2?.label}
                  </a>
                  <a href={link.label3?.link} target="_blank">
                    {link.label3?.label}
                  </a>
                </S.Column>
              </S.ColumnContent>
            ))}

            {supportArray.map((link, i) => (
              <S.ColumnContent key={i} style={{ width: '40%' }}>
                <h4>{link.title}</h4>
                <S.ColumnSupport style={{ gap: 8 }}>
                  <a href={link.label1?.link} target="_blank">
                    <FaWhatsapp size={13} />

                    {link.label1?.label}
                  </a>
                  <a href={link.label2?.link}>
                    <MdOutlineEmail size={13} />

                    {link.label2?.label}
                  </a>
                </S.ColumnSupport>
              </S.ColumnContent>
            ))}
            {/* <S.ColumnButton>
              <S.Column style={{ gap: 0 }}>
                <h4>Keed, no seu celular!</h4>
              </S.Column>
              <S.Column style={{ gap: 8 }}>
                <button
                  onClick={() => {
                    if (process.env.REACT_APP_APPSTORE_APPLE) {
                      window.open(
                        process.env.REACT_APP_APPSTORE_APPLE,
                        '_blank'
                      )
                    }
                  }}
                >
                  <Apple /> App Store
                </button>
                <button
                  onClick={() => {
                    if (process.env.REACT_APP_APPSTORE_GOOGLEPLAY) {
                      window.open(
                        process.env.REACT_APP_APPSTORE_GOOGLEPLAY,
                        '_blank'
                      )
                    }
                  }}
                >
                  <Android /> Play Store
                </button>
              </S.Column>
            </S.ColumnButton> */}
          </S.RowContent>
        </S.Row>
        <S.DivisorContainer>
          <S.Divisor />
        </S.DivisorContainer>
        <S.Text>© 2024 Keed Group - Todos os Direitos Reservados</S.Text>
      </S.Wrapper>
    </S.Container>
  )
}

export default Footer
